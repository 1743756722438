








import {Component, Vue} from 'vue-property-decorator';
import PageHeader from '@/components/common/Header.vue';
import PageFooter from '@/components/common/Footer.vue';

@Component({ 
  name: 'App',
	components: {
    PageHeader,
		PageFooter
	}
})
export default class App extends Vue {
}
