import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

import HomePage from "@/pages/Homepage-pre.vue";
import About from "@/pages/About.vue";
import Brand from "@/pages/Brand.vue";
import Review from "@/pages/Review.vue";
import Mentor from "@/pages/Mentor.vue";
import CustomCenter from "@/pages/CustomCenter.vue";
import Faq from "@/pages/custom/Faq.vue";
import Resume from "@/pages/Resume.vue";
import Partnership from "@/pages/custom/Partnership.vue";
import Inquiry from "@/pages/custom/Inquiry.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Index",
    components: {
      root: HomePage,
    },
  },
  {
    path: "/android",
    name: "Android",
    components: {
      root: HomePage,
    },
  },
  {
    path: "/python-backend",
    name: "Python",
    components: {
      root: HomePage,
    },
  },
  {
    path: "/frontend",
    name: "Frontend",
    components: {
      root: HomePage,
    },
  },
  {
    path: "/about",
    name: About.name,
    components: {
      root: About,
    },
  },
  {
    path: "/brand",
    name: Brand.name,
    components: {
      root: Brand,
    },
  },
  {
    path: "/review",
    name: Review.name,
    components: {
      root: Review,
    },
  },
  {
    path: "/mentor",
    name: Mentor.name,
    components: {
      root: Mentor,
    },
  },
  {
    path: "/customer-center",
    name: CustomCenter.name,
    components: {
      root: CustomCenter,
    },
  },
  {
    path: "/customer-center/faq",
    name: Faq.name,
    components: {
      root: Faq,
    },
  },
  {
    path: "/resume",
    name: Resume.name,
    components: {
      root: Resume,
    },
  },
  // {
  //   path: '/customer-center/partnership',
  //   name: Partnership.name,
  //   components: {
  //     root: Partnership
  //   },
  // },
  // {
  //   path: '/customer-center/inquiry',
  //   name: Inquiry.name,
  //   components: {
  //     root: Inquiry
  //   },
  // },
  // {
  //   path: '/customcenter',
  //   name: CustomCenter.name,
  //   components: {
  //     root: CustomCenter
  //   },
  //   children: [
  //     {
  //       path: 'faq',
  //       name: Faq.name,
  //       components: {
  //         root: Faq
  //       }
  //     },
  //   ]
  // },

  {
    path: "*",
    redirect: "/",
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
