



































































import {Component, Vue} from 'vue-property-decorator';
import backendMentors from '../data/backend-mentors';
import androidMentors from '../data/android-mentors';
import frontendMentors from '../data/frontend-mentors';

@Component({
  name: 'Mentor',
})

export default class Mentor extends Vue {
  data() {
    return {
      backendMentorsInfo: backendMentors,
      androidMentorsInfo: androidMentors,
      frontendMentorsInfo: frontendMentors,
    }
  }

  mentors: any = [];
  selectedIndex: any = 0;

  viewType = [
    {name: '전체'},
    {name: '자바 백엔드'},
    {name: '안드로이드'},
    {name: '프론트엔드'},
  ];

  selectedViewTypeName: any = '';

  created() {
    this.mentors = backendMentors.concat(androidMentors).concat(frontendMentors)
    const newList = this.mentors.filter((mentor, idx, arr) => {
      return arr.findIndex((item) => item.name === mentor.name) === idx
    });
    this.mentors = newList;
  }

  moveTab(idx) {
    this.selectedIndex = idx;
    this.selectedViewTypeName = this.viewType[this.selectedIndex].name;
  }

// 포함한 태그 필터링
  isContained(tag) {
    if (!tag) return false;
    return tag.indexOf(this.selectedViewTypeName) !== -1;
  }

  setFilteredItems(items) {
    if (this.selectedIndex === 0) return items;
    return items.filter((item) => this.isContained(item.tag));
  }

  mounted(): void {
    this.$analytics.logEvent('view_mentor');
  }

}
