export default [
  {
    tab: "STEP 00-1",
    title: "F-Lab이 생각하는 이상적인 주니어 프론트엔드 개발자",
    list: [
      "- Angular, React, Vue는 선택일뿐 본질인 JS를 깊게까지 알고 사용해야 합니다.",
      "- React, Vue 등에 의존하지 않고 SPA와 VDOM을 자바스크립트로 구현할 수 있는 수준이여야 합니다.",
      "- 타입스크립트를 단순 사용하는 것이 아닌 유지보수성에 대해 고민해보고 본질을 볼 수 있어야 합니다.",
      "- Webpack, Babel 등 Javascript Ecosystem에 대해 그냥 사용하는 것이 아닌 “잘 알고” 사용해야 합니다.",
      "- 실무가 가능하도록 주 프레임워크가 하나 이상 있어야하며, 해당 프레임워크를 최적화해서 사용할 수 있어야합니다.",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 00-2",
    title: "이 멘토링의 목적",
    list: [
      "- 주요 IT 업계에서 요구하는 “제대로 된” 수준의 주니어 프론트엔드 개발자가 되기 위한 학습.",
      "- 원리와 이유를 찾아갈 수 있는 깊이있는 학습 습관 들이기.",
      "- 프로젝트를 진행하며 팀원간의 원활한 협업을 위한 소프트스킬 학습 및 활용",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 00-3",
    title: "프로젝트 진행방식",
    list: [
      "- Github를 이용하여 협업합니다.",
      "- 프로젝트 주제는 자유, 멘토는 이 프로젝트를 “잘” 만들 수 있는 방법에 대해 가이드합니다.",
      "- 멘토는 멘티가 올린 코드에 대해 코드리뷰를 제공합니다.",
      "- Github를 이용해 이슈 및 진행 상황을 관리하며 협업을 위한 도구 사용법에 익숙해집니다.",
      "- 함께 성장할 수 있는 문화에 익숙해지기 위해 멘티들끼리도 멘토의 가이드 하에 서로에게 피드백과 코드리뷰를 진행합니다.",
      "- 프로젝트에 대한 것들은 최대한 문서화하고, 피드백을 받으며 읽는 사람을 배려하는 글 쓰기를 연습합니다.",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 01",
    title: "멘토링 신청 후",
    list: [
      "- 결제 완료시 멘토링 시작일까지 사전 준비 사항들에 대해 안내.",
      "- 매 월 22일, 팀 빌딩을 진행하며 카톡 연락을 통해 멘토링 시간 조율",
      "- 조율 완료시 멘토링 시간에 맞는 멘토 배정 후 팀 빌딩 완료",
      "- 매 월 마지막 주 토요일 오후 3시에 오프라인 OT 및 네트워킹 진행 (참석여부 자유)",
      "- 팀 빌딩 1주 후 배정받은 요일과 시간에 첫 멘토링 시작 (매 주 동일한 요일과 시간에 진행)",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 02",
    title: "Javascript에 대한 Deep Dive",
    list: [
      "- Prototype, Async/Await와 Promise 등 심도있는 이론 학습 진행",
      "- 화상 멘토링시 멘토가 기술면접 방식으로 꼬리 질문을 통한 피드백과 해설 제공",
      "- 궁금한 점은 메신저를 통해 멘토에게 상시로 질문 가능",
    ],
    paragraph: `"내가 사용하고 있는 기술들이 애초에 무엇인지" 근본적인 것들에 대해 고민을 해나갑니다.`,
    images: [],
  },
  {
    tab: "STEP 03",
    title: "우리의 코드가 동작하는 환경에 대한 깊은 이해",
    list: [
      `- 브라우저에 대한 깊은 이해 (V8엔진, 이벤트루프 등)`,
      `- Javascript Echosystyem (Webpack, Babel 등)`,
    ],
    paragraph: `우리의 코드가 동작하는 환경에 대해 학습함으로써 우리의 어플리케이션이 어떤 환경에서 어떤 과정을 거쳐 동작하게 되는지 이해하기 위한 기반을 다집니다.`,
    images: [],
  },
  {
    tab: "STEP 04",
    title: "React Deep Dive",
    list: [
      "- 주 프레임워크로 리액트를 능숙하게 다루기 위한 과정입니다.",
      "- 클린코드, 컴포넌트 추상화 등 대규모 프로젝트도 유지보수가 용이한 구조를 만들기 위해 고민합니다.",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 05",
    title: "성능 테스트 및 튜닝",
    list: [
      "- 사용자 경험 향상을 위해 개발한 어플리케이션의 성능을 측정 및 튜닝합니다",
      "- Light House, Developer Tools 등을 활용합니다.",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 06",
    title: "단위 테스트 Deep Dive",
    list: [
      "- 코드의 동작을 보장하고, 버그의 발생 가능성을 줄일 수 있도록 테스트에 대해 학습하고 적용해봅니다.",
      "- Jest를 이용해 테스트를 작성합니다.",
    ],
    paragraph: `서비스는 개발도 중요하지만 안정적으로 운영하는 것도 중요합니다.`,
    images: [],
  },
  {
    tab: "STEP 07",
    title: "Typescript Deep Dive",
    list: [
      "- 동적타입언어와 정적타입언어의 차이에 대해 잘 알고, 타입스크립트를 그냥 사용하는 것이 아닌 '잘' 알고 사용할 수 있도록 학습합니다.",
    ],
    paragraph: `모든 과정을 아주 깊게 공부해야하기 때문에 몇 개월 정도의 비교적 짧은 기간으로는 5번 이상 진행하기 어려울 수 있습니다. 하지만 "제대로" 빠르게 학습하신다면 더 많은 것들을 배우실 수 있습니다.`,
    images: [],
  },
  {
    tab: "STEP 08",
    title: "취업 지원",
    list: [
      "- 회사 지원시 지원 전략 조언",
      "- 회사 지원시 기술면접, 인성면접에 대한 모의 면접 진행 & 피드백",
    ],
    paragraph: "",
    images: [],
  },
];
