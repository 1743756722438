



























import Vue from 'vue';
import questionsData from '@/data/questions';

export default Vue.extend({
    data() {
        return {
            search: undefined,
            questions: questionsData,
        };
    },
    
    mounted(): void {
      this.$analytics.logEvent('view_customer_center_faq');
    }
})
