

































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'; 

@Component({
  name: 'About'
})

export default class About extends Vue { 
  mounted(): void {
    this.$analytics.logEvent('view_about');
  }
}
