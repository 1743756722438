










































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "CustomCenter",
})
export default class CustomCenter extends Vue {
  mounted(): void {
    this.$analytics.logEvent("view_customer_center");
  }
}
