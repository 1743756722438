








































































































































































































import { Component, Vue } from "vue-property-decorator";
import Reviews from "@/data/reviews";

@Component({
  name: "Review",
  components: {},
})
export default class Review extends Vue {
  reviewItems: any = Reviews;
  selectedReview: Reviews[0];
  selectedViewTypeName: any = "";
  selectedIndex: any = 0;

  viewType = [
    { name: "전체" },
    { name: "#비전공자" },
    { name: "#국비학원 출신" },
    { name: "#경력자" },
    { name: "#네카라쿠배 합격" },
    { name: "#대기업 합격" },
    { name: "#SI출신" },
    { name: "#연봉 5000 이상" },
    // {name: '#연봉 4000 이상'},
    // {name: '#유니콘 기업 합격'},
  ];

  data() {
    return {
      items: Reviews,
    };
  }

  moveTab(idx) {
    this.selectedIndex = idx;
    this.selectedViewTypeName = this.viewType[this.selectedIndex].name;
  }

  //완전 동일한 태크 필터링
  // parseTags(tags) {
  //    if(!tags || tags.indexOf("#") == -1) return [];
  //    return tags.split("#").filter(v => v.trim()).map((v) => "#" + v.trim());
  //  }
  //  setFilteredItems(items) {
  //    const selectedViewTypeName = this.viewType[this.selectedIndex].name;
  //    return items.filter((item) => this.parseTags(item.tags).includes(selectedViewTypeName));
  //  }

  // 포함한 태그 필터링
  isContainTags(tags) {
    if (!tags || tags.indexOf("#") == -1) return false;
    return tags.indexOf(this.selectedViewTypeName.substring(1)) !== -1;
  }

  setFilteredItems(items) {
    if (this.selectedIndex === 0) return (this.reviewItems = items);
    return (this.reviewItems = items.filter((item) =>
      this.isContainTags(item.tags)
    ));
  }

  private reviewModal = false;

  onClickReview(index) {
    this.reviewModal = true;
    document.body.classList.add("modal-open");
    this.selectedReview = this.reviewItems[index];
  }

  closeModal(): void {
    this.reviewModal = false;
    document.body.classList.remove("modal-open");
  }

  mounted(): void {
    this.$analytics.logEvent("view_review");
  }
}
