export default [
  {
    name: "정O 님 ",
    type: "신입",
    info: "네이버(인턴) / 카카오(정규직) 동시 합격, 카카오 입사",
    projects: "https://github.com/f-lab-edu/food-delivery",
    tags:
      "#국비학원 #전공자 #신입 #네카라쿠배 합격 #대기업 합격 #멘토링 기간 7개월 #연봉 5000 이상",
    paragraph: `
      <strong>[F-Lab의 장점]</strong><br/>
      - 무엇을 공부해야할 지 모를 때, 어떤 것을 우선적으로 공부할지 방향을 잡아줍니다.<br/>
      - 공부를 할 때 애매하게 알고 넘어가지 못하게 합니다. 어떻게 익힐지, 어떻게 사용할지 공부의 방법을 알려줍니다.<br/>
      - 1:1 밀착 코드 리뷰를 받을 수 있고 생각 없이 짠 코드가 보이면 가차없이 리뷰를 남겨줍니다. 그로 인해 한줄의 코드를 짜도 꼭 이렇게 짜야하는지 고민을 하게 되는 습관을 기를 수 있습니다.<br/><br/>
      <strong>[F-Lab의 단점]</strong><br/>
      - 하나를 배워도 깊이있게 배워야 넘어갈 수 있기 때문에 진도가 느리게 느껴집니다.<br/>
      - 위와 같은 이유로 멘토링 초반에 조급함이 느껴지기도 합니다.
      학교, 학원을 다닐 때는 멀게만 느껴졌던 개발자의 길이 멘토링을 받으며 피부에 확 와닿을 만큼 가깝게 느껴졌습니다. 막연하게 어떤걸 준비해야할지, 지금 학원에서 알려주는대로, 흘러가는대로 준비했다면 결코 지금처럼 좋은 회사에서 커리어를 이어나가기 어려웠을 것입니다.
      
      <strong>
      <span class="highlight">멘토링을 통해 익힌 좋은 공부 습관</span>
      </strong>
      
      을 통해 회사에서 새로운 업무가 와도 지금까지 익힌 공부 방법을 통해 빠르게 습득할 수 있었습니다. 6개월이라는 시간동안 정말 많은것을 배웠고 덕분에 좋은 커리어를 이어나가며 앞으로도 깊은 지식 습득을 할 수 있을것 같습니다.
      `,
  },
  {
    name: "오OO 님 ",
    type: "3년 차 이직",
    info: "카카오 / 넥슨(정규직) 합격",
    projects: "https://github.com/f-lab-edu/used-market-server",
    tags:
      "#연봉 70%이상 인상 #경력자 #대기업 합격 #멘토링 기간 8개월 #연봉 5000 이상",
    paragraph: `
    상시로 커리어에 관한 전반적인 내용을 피드백 받을 수 있고, 일반적인 학원에서 강의 방식이 아닌 생각하는 법을 다시 한번 피드백 주시는 점이 좋았습니다. <br/><br/>자신의 기술력을 보여 줄 수 있는 언어, 코드 작성, 블로그 글 작성 등 여러 부분에서 

    <strong>
    <span class="highlight">단기적인 성과가 아닌 미래의 자발적인 프로그래머서의 자질</span>을 높여주는 것 같아서 그 부분이 제일 만족스러웠습니다.
    </strong>
    `,
  },
  {
    name: "이OO 님 ",
    type: "신입",
    info: "네이버(정규직) / 카카오(인턴) 합격",
    projects: "https://github.com/f-lab-edu/shoe-auction",
    tags:
      "#전공자 #신입 #네카라쿠배 합격 #대기업 합격 #멘토링 기간 6개월 #연봉 5000 이상",
    paragraph: `
  막연하다고 느껴질 수 있는 ‘좋은 개발자’라는 타이틀에 한 걸음 다가갈 수 있도록 방향을 제시받음으로서 확신을 가지고 개발에 매진할 수 있도록 해주는 과정이었습니다. 이로써 이전보다 훨씬 빠르게 성장 한다는 것을 체감할 수 있었고, 이는 더 많은 시간을 투자하게 되는 선순환을 만들어 주었습니다.<br/><br/>
   
   에프랩에서 멘토링을 진행하면서 읽었던 기술 서적의 내용과 진행했던 프로젝트에 대한 고민들이 입사 시 인터뷰를 진행할 때 직접적으로 큰 도움이 되었습니다. 또한 기술에 관한 내용 뿐 아니라 <strong><span class="highlight">협업과 커뮤니케이션에 대한 깊은 고민</span></strong>을 할 수 있는 값진 시간이었습니다.<br/><br/>
  
   개발자로서 좋은 습관들을 쌓을 수 있었고, 장기적으로 보았을 때 어떤 방식으로 나의 가치를 높일 수 있는지를 알게 됨으로써 멘토링 과정이 종료된 이후에도 <strong><span class="highlight">스스로 성장할 수 있는 원동력</span></strong>을 가지게 된다는 것이 큰 메리트라고 느껴졌습니다.
    `,
  },
  {
    name: "박OO 님 ",
    type: "3년차 이직",
    info: "LG CNS -> 카카오페이 이직",
    projects: "https://github.com/f-lab-edu/share-my-hobby",
    tags:
      "#SI -> 카카오 이직 #경력자 #네카라쿠배 합격 #대기업 합격 #멘토링 기간 6개월 #SI출신 #연봉 5000 이상",
    paragraph: `
   SI/SM 기업에서 서비스 기업으로 이직하기 위해 혼자 공부하면서 가장 힘들었던 것은, 구체적으로 어떤 것을 어떻게 공부해야할지 방향성을 잡기 힘들었다는 것이었습니다. 하지만 F-Lab 멘토링을 통해 저는 학습의 방향성을 잡을 수 있었고, 그 방향성을 잃지 않고 꾸준히 나아갈 수 있는 힘을 얻을 수 있었습니다. 무언가 하나를 학습하더라도 혼자였을 때 보다 훨씬 더 깊고 더 넓게 생각해 볼 수 있었고, 이해한 것을 직접 설명하고 반복적으로 피드백을 받으면서 학습한 내용들을 검증할 수 있다는 점들이 정말 좋았습니다.
<br/><br/>
멘토링을 수료한 지금, 제가 얻은 가장 큰 수확은 앞으로 더욱 성장할 수 있을 것이라는 자신감인 것 같습니다. 사용법만 알면 된다고 생각했던 제가 이제는 기술의 철학과 특징, 동작 원리를 공부하고, 그것을 도입했을 때의 트레이드 오프까지 고려하는 습관을 갖게 되었습니다. 그리고 새로운 기술에 대한 두려움보다는 호기심과 얼른 내 것으로 만들고 싶다는 욕심이 훨씬 커졌습니다. 이런 모든 변화들이 제가 앞으로 어떤 도메인, 어떤 개발 환경에서든 좋은 개발자로서 성장할 수 있을거라는 자신감을 갖게 해주었습니다.
<br/><br/>
6개월 동안 한결같이 저의 목표를 응원해주시고 성장할 수 있도록 이끌어주신 멘토님, 정말 감사합니다! 지금처럼 기술을 학습하는 태도를 잊지않고 꾸준히 성장해서, 언젠가 저 역시 누군가의 성장을 도울 수 있을만큼 더 좋은 개발자가 되겠습니다!
    `,
  },
  {
    name: "배OO 님 ",
    type: "1년차 이직",
    info: "삼성전자(정규직) 합격",
    projects: "https://github.com/f-lab-edu/shoe-auction",
    tags: "#전공자 #경력자 #멘토링 기간 6개월 #대기업 합격 #연봉 5000 이상",
    paragraph: `
  컴퓨터공학과를 졸업했지만 졸업만 했을 뿐 CS 기초도 부족했고 개발과도 거리가 멀었습니다.
<br/><br/>
만족스럽지 않은 회사에 다니며 진로에 대해 고민하던 와중 지인으로부터 에프랩을 소개받게 되어 시작하게 되었습니다
<br/><br/>
저처럼 졸업 후 조언받을 사람이 주위에 없는 분들에게 멘토링 과정은 더욱 큰 도움이 될 것이라고 생각합니다.
<br/><br/>
멘토링 과정을 진행하며 이전에는 왜 배우는지 체감하지 못하던 CS 기초 지식과 이론 공부에 대해 중요성을 깨달을 수 있었고 깊게 공부할 수 있었습니다.
<br/><br/>
매주 학습한 내용에 대해 깊게 고민해봐야 답할 수 있는 질문을 해주시기 때문에 자연스럽게 혼자 공부하면서도 스스로 질문하고 생각하는 습관을 얻게 되었습니다.
<br/><br/>
멘토링을 통해 얻은 습관은 추후 어떤 공부를 하게 되더라도 큰 도움이 될 것 같습니다.
    `,
  },
  {
    name: "안OO 님 ",
    type: "신입",
    info: "채널코퍼레이션(정규직) 합격 / 초봉 5000 중반대",
    projects: "https://github.com/f-lab-edu/daangn-market-used-trading",
    tags: "#전공자 #신입 #멘토링 기간 8개월 #연봉 5000 이상",
    paragraph: `
  좋은 개발자, 잘하는 개발자를 목표로 달려오면서 가장 크게 부족함을 느낀 것은 방향성의 부재였습니다. 
<br/><br/>
- 무엇을 공부해야하는지<br/>
- 어떠한 것들에 관심을 가져야하는지<br/>
- 내가 노력하고 학습하고있는 방향이 올바른지<br/>
- 내가 작성한 코드가 과연 좋은 코드인지<br/>
<br/><br/>
 컴퓨터공학부를 졸업하고 기업이 주관하는 교육 프로그램을 이수하면서 여러가지 이론과 기술들에 대해 배우고 익혔지만 그럼에도 불구하고 여전히 내가 잘하고 있는가, 올바른 방향으로 나아가고 있는가에 대한 의문점을 해결해주지는 못하였습니다.
<br/><br/>
 F-Lab 멘토링을 선택한 이유는 바로 이러한 방향성을 제시해줄 수 있는 멘토의 필요성을 절실하게 느꼈기 때문입니다. 
<br/><br/>
F-Lab 멘토링을 통해 얻을 수 있는 점은 다음과 같습니다.
<br/><br/>
1. 무엇에 관심을 가지고 어떻게 노력해야하는지 방향을 제시해줍니다.<br/>
    - 기술적인 문제 뿐만 아니라 개발자로써 어떠한 것들에 관심을 가지고 노력해야하는지 멘토링을 통해 얻을 수 있습니다.<br/>
    - 기술에 대해 학습하고, 이를 고민하고 해결한 방향이 올바른지, 내가 작성한 코드가 좋은 코드인지 멘토링을 통한 끊임없는 커뮤니케이션과 피드백을 통해  목표를 동기화해 나아갈 수 있습니다.<br/>
2. 기술적인 문제에 대해 고민하고 해결할 수 있는 힘을 기를 수 있도록 해줍니다.<br/>
    - 여러가지 기술서적들을 읽고 토론하면서 생각의 깊이와 폭을 넓힐 수 있도록 도와줍니다.<br/>
    - 뿐만 아니라 매번 멘토링 때마다 기술 면접을 방불케하는 질의 응답을 통해 학습한 내용을 제대로 이해했는지 이를 끊임없이 검증하고 개선해 나아갈 수 있도록 도와줍니다.<br/>
3. 하드 스킬 뿐만 아니라 소프트 스킬에 대한 역량을 기를 수 있습니다.<br/>
    - 멘토링을 통해 기술적인 역량 뿐만 아니라 자신의 의도를 잘 전달할 수 있는 커뮤니케이션 역량에 대해서 기를 수 있습니다.<br/>
    - 문서화에 대한 중요성과 피드백을 통해 읽기 좋은 문서와 자신의 성과가 잘 드러나는 글을 작성할 수 있도록 가이드 해줍니다.<br/>
4. 끊임없는 취업지원<br/>
    - 주기적인 자기소개서 및 포트폴리오 피드백을 통해 자신의 성과에 대해 어필하고 잘 드러낼 수 있는 문서를 작성할 수 있도록 도와줍니다.<br/>
    - 매번 멘토링 시간마다 이루어지는 질의 응답을 통한 검증을 통해 기술면접을 간접적으로 경헙하고 대비할 수 있습니다.<br/>
<br/><br/>
 F-Lab 멘토링 기간 동안 개인적으로 대학 4년과 부트 캠프 1년의 시간보다 더 비약적인 성장을 할 수 있는 시간이었습니다. 짧은 시간동안 정말 많은 것에 대해 배웠고 좋은 개발자로 성장하기 위해 어떻게 노력해야하는지 나의 강점을 어떻게 하면 잘 드러낼 수 있는지, 스스로 성장할 수 있도록 올바른 방향성과 원동력을 멘토링을 통해 얻을 수 있었던 것 같습니다.
    `,
  },
  {
    name: "이OO 님 ",
    type: "신입",
    info: "하이퍼커넥트(정규직) 합격",
    projects: "https://github.com/f-lab-edu/make-delivery",
    tags: "#전공자 #신입 #멘토링 기간 6개월 #유니콘 기업 합격",
    paragraph: `
    처음엔 비용 때문에 할까 말까 망설였는데, 멘토링을 하면서 느낀 건 '하길 잘했다' 는 생각이 듭니다. 교육 과정도 만족스럽고 코드 리뷰 해주시는 거랑 질문 대답해 주시는거나, 교육 방향을 잡아주시는 것도 만족스럽습니다. 책을 읽고 온 뒤 중요한 이론들을 면접 처럼 확인 해주는 과정에서 제가 잘못 알고 있던 것을 바로 잡고, 중요한 부분을 놓치지 않게 해주시는 점도 매우 만족스럽습니다.<br/><br/>

    <strong>
    멘토링에서 가장 만족스러운 점은 혼자 공부하면서 방향이 틀어질 때가 정말 많았고, <span class="highlight">어떤 것을 공부해야 취업이나 개발자로써 성장하는지를 깨닫지 못했는데 그 방향을 계속해서 잡아주시기 때문에</span> 그 부분이 가장 만족스럽습니다.<br/><br/>
    </strong>

    또한, 자소서나 프로젝트 포트폴리오 부터 프로젝트 면접 까지 전 과정을 같이 준비하기 때문에 본인이 공부만 열심히 한다면 큰 도움이 될 것 같습니다.
    `,
  },
  {
    name: "길OO 님 ",
    type: "신입",
    info: "네이버(정규직) 합격",
    projects: "https://github.com/f-lab-edu/gift-club",
    tags: "#전공자 #신입 #네카라쿠배 합격 #대기업 합격 #연봉 5000 이상",
    paragraph: `
    0. 저는 <strong><span class="highlight">한만큼 가져가는 구조</span></strong>에 잘맞아서 좋았습니다. 본인이 주입식 교육보다는 알아서 체득하는게 좋다 하시는 분은 원래 혼자하는것에 뒤에 부스터 효과를 느낄 수 있을 것입니다
    <br/><br/>
    1. 사실 개발적 성장은 <strong><span class="highlight">책과 글쓰기의 중요성</span></strong>에 대해 아는 것이 가장 좋았던 것 같아요
    <br/><br/>
    2. 어떻게 보면 계속 혼자 결정 결정의 순간인 취업 혹은 이직 과정에서 제 상황에 귀 기울여주는 멘토가 있다는 것은 억만금을 내도 못 얻을 행운입니다 
    `,
  },
  {
    name: "이OO 님 ",
    type: "신입",
    info: "LG CNS(정규직) 합격 / 연봉 4000 중반대",
    projects: "https://github.com/f-lab-edu/runmate",
    tags: "#전공자 #신입 #멘토링 기간 6개월 #대기업 합격",
    paragraph: `
   흔한 코딩 학원에서 배우는 사소한 코드 짜는 것에 집중된 교육이 아니라 실제 현업에서 필요한 스킬들을 능력자 분들에게 들을 수 있어서 가장 큰 차이점이자 강점이라 생각합니다.<br/><br/>
 특히 멘토링 이후에도 커리어를 발전시키는 방식에 대한 노하우를 얻을 수 있어서 좋았습니다. 그리고 교육 기간이 끝나도 멘토님과 sns로라도 연락을 유지할 수 있어서 커리어에 대한 고민을 터놓을 수 있는 든든한 인생/커리어 멘토님을 얻을 수 있어서 만족스럽습니다.   
    `,
  },

  {
    name: "고OO 님 ",
    type: "신입",
    info: "렌딧(정규직) 합격",
    projects: "https://github.com/f-lab-edu/gift-club",
    tags: "#비전공자 #신입 #멘토링 기간 7개월 #초봉 4500만원 이상",
    paragraph: `
    멘토링을 진행하면서 좋았던 점은 먼저, back-end 개발자가 되기 위해서 어떤 식으로 개발 공부를 해 나가야 하는지에 대한 방법론적인 부분과 더불어 어떠한 방향으로 나아가야 하는지에 대한 방향성을 제시 받을 수 있다는 점이었습니다. 투입하는 노력의 양만큼 중요한 것이 노력의 방향을 결정하는 일이라고 생각합니다. 노력의 방향이 잘못되면 아무리 노력해도 무용지물이기 때문입니다. 저는 특히나 비전공자로서 이전에 따로 개발공부를 해본 적이 없기에 개발에 처음 입문했을 때, 눈 앞에 놓여있는 방대한 학습량을 보면서 방향을 종잡을 수가 없었기에 망망대해에 목적없이 표류하고 있는 배 같은 느낌이 들었습니다. 
<br/><br/>
그러나 멘토링을 시작하고, 멘토님께서 올바른 방향을 찾는데 도움이 되도록 지속적으로 가이드해 주셔서 방향성에 대한 고민이 덜어지고, 어떻게 하면 공부 시간을 최대한으로 끌어올리고 그 시간 동안 최대의 효율성을 발휘할 수 있을지를 고민하는데 초점을 맞출 수 있었습니다.
<br/><br/>
두 번째로는 기능 구현에 그치지 않고, 항상 더 깊은 곳까지 보면서 설계하도록 훈련하는 도움을 받을 수 있었다는 점입니다. 특히나 멘토님께서 멘티가 작성한 모든 코드를 일일이 리뷰해주시기 때문에 코드를 작성하기 전 능동적으로 ‘더 나은 방법은 없는지’, ‘더 깔끔하게 작성할 수는 없는지’ 등을 항상 고민하여 코드를 작성하는 습관을 얻게 되었고, 코드를 작성하고 난 후에도 잘못 생각했거나 놓쳤던 부분에 대한 피드백을 받을 수 있은 후 이를 다시 소스 코드에 반영하는 과정을 통해서도 많은 깨달음을 얻을 수 있었습니다.  
<br/><br/>
세 번째로는 구직활동에 있어서 끝까지 직접적인 도움을 받을 수 있다는 점입니다. 개발 분야에 처음 입성한 만큼 이력서 작성이 매우 막막했는데, 제가 작성해 온 이력서를 멘토링 시간에 함께 리뷰하고 프로젝트를 진행하면서 달성한 성과도 효율적으로 강조할 수 있는 방법을 코칭해주신 덕분에 만족할 만한 이력서를 완성할 수 있었습니다. 기술 면접 관련해서는 얼마나 깊게 공부했는지를 확인하는 질문들을 멘토링 시간에 매주 해 주셨기 때문에 자연스럽게 면접대비가 되었으며, 좀 더 심화된 내용이나 부족한 부분에 대해서도 공부할 키워드를 제공해 주셔서 직접 찾아서 학습하는 습관도 함께 기를 수 있었습니다. 이외에도 전반적인 면접 관련 멘토링을 진행하는 시간할 때에는 면접관 경력을 바탕으로 면접관의 시선에서 개선할 부분에 대해서 피드백을 주셨고 이를 수용한 덕분에 첫 면접에서 바로 좋은 소식을 얻게 되었습니다. 
<br/><br/>
사실 비 전공자로서 처음 멘토링을 시작했을 때는 6개월로는 멘토링 기간이 충분하지 않을 것이라 생각했습니다. 특히나 새로운 지식을 배우고 이를 프로젝트에 녹여내는 부분이 사실 쉽지만은 않았고, 오류를 포함한 여러 예상치 못한 문제들이 발생하면서 마음 고생을 많이 하기도 했습니다. 그래도 정말 해결할 수 없는 문제가 생길 때마다, 이에 대해 질문하고 조언을 얻을 수 있는 대상이 있다는 것 만으로도 많은 힘이 되었고, 실제로도 많은 도움을 받았습니다. 여기에 더불어, 방향성에 대한 고민을 덜었기에 현재 공부하고 있는 내용들에 대한 의구심 없이 공부에만 전념할 수 있었고, 정말 노력을 한 만큼 그에 대한 보상을 얻어간다는 느낌을 받을 수 있었습니다. 덕분에 6개월이라는 비교적 짧은 시간에 예상치 못한 성과를 달성할 수 있었습니다. 
<br/><br/>
개발자는 평생 공부해야 할 직업이기 때문에 지금까지 했던 것보다 앞으로 공부해야 할 내용들이 훨씬 더 많을 것입니다. 하지만 멘토링을 진행하면서 프로젝트와 이력서의 완성 및 취업이라는 성과 외에도, 앞으로 더 큰 밑바탕이 되어 줄 ‘어떻게 성장해 나갈지’에 대한 부분을 배울 수 있었습니다. 이를 활용해 항상 발전하는 개발자가 되도록 끊임없이 배워나가겠습니다. 멘토링 기간 동안 정말 많은 것을 배웠고 감사했습니다.
    `,
  },
  {
    name: "정OO 님 ",
    type: "1년 미만 경력",
    info: "식스샵(정규직) 합격",
    projects: "https://github.com/f-lab-edu/sns-itda",
    tags: "#비전공자 #경력자 #초봉 5000 이상 #멘토링 기간 11개월",
    paragraph: `
    <strong>
    지금까지 요구사항에 따라, 어떻게든 '기능을 만들기만 하면 그게 개발을 잘하는 것'이라고 생각 했습니다. 하지만 <span class="highlight">이 멘토링을 통해 단순 기능 구현 이상의 개발</span>을 배울 수 있었습니다. 다양한 측면에서 좋은 개발을 할 수 있도록 멘토님께서 피드백을 주시고 이를 바탕으로 공부하고, 바로 적용시켜 볼 수 있다는 점이 가장 큰 장점 중 하나라고 생각합니다.<br/><br/>
    </strong>

    기본 문법부터 신입 레벨에서는 쉽게 경험할 수 없는, 어플리케이션 운영까지 해볼 수 있어 전체적인 개발 프로세스에 관한 지식을 얻을 수 있었습니다. 또한 수동적으로 지식을 받아먹는 것이 아니라 스스로 지식을 터득할 수 있는 방법을 익혀나갈 수 있기 때문에 꾸준히 노력만 하신다면 좋은 개발자로 성장할 수 있는 발판을 마련할 수 있을 것입니다.
    `,
  },
  {
    name: "이OO 님 ",
    type: "1년차 이직",
    info: "오늘식탁 합격 / SI -> IT서비스 기업 이직 / 연봉 37% 상승",
    projects: "https://github.com/f-lab-edu/event-recommender-festa",
    tags: "#비전공자 #경력자 #SI출신 #국비학원 출신 #멘토링 기간 11개월",
    paragraph: `
   비전공자로써 첫 직장으로 SI회사에 가게되었지만 개발문화나 환경, 성장성, 처우, 모든 것에 있어 매우 열악했었습니다. 개발자로써 보다 성장할 수 있는 환경이 갖춰진 자체서비스 회사에 갈려면 기존의 공부방식과는 달라야 한다는 것을 알고있었지만 어떻게 해야할지 매우 막막했습니다. 
<br/><br/>
그러다 우연히 F-Lab에 대해 알게되었고 학습의 방향성을 바로잡고 저의 막막함을 풀어줄 수 있을 것이라 판단해 멘토링을 시작하게 됐습니다. 일일히 알려주기보단 스스로 해결하고 학습할 수 있도록 길을 제시해주며, 항상 "왜" 라는 질문을 끊임없이 던져주어 작은 기능을 개발하거나 하나의 기술에 대해 공부하더라도 깊은 생각을 할 수 있도록 해주었습니다. 지난 1년간 이러한 방식으로 멘토링을 하게 된 결과, 제가 원하는 개발문화와 환경을 갖춘 서비스 회사에 첫 발을 내딛을 수 있었습니다. 
<br/><br/>
이전 때 처럼 유지보수성을 생각하지않은, 그저 기능이 동작하게만 하고 모든 코드를 복사&붙여넣기 식으로 개발하는게 아닌 더 좋은 방법은 없는지, 작은 곳이라도 놓친 부분이 없는지, 이 코드가 읽기 좋은 코드인지 등 여러 부분에서 질 높은 코칭을 받았기에 빠른 커리어 전환이 가능했다고 생각합니다. 
<br/><br/>
구직활동에 있어서도 이력서, 자기소개서, 면접준비 와 같은 중요한 부분에서 면접관의 눈으로 부족한 점과 개선방안을 피드백 주셨기 때문에 실제 면접장에서도 좋은 반응을 얻을 수 있었습니다. 
<br/><br/>
앞으로 개발자로 살아가는데 있어 반드시 필요한 밑거름을 만들어 주기 때문에 멘토링이 끝나도 충분히 스스로 성장할 수 있는 자질을 배워갈 수 있다는 것이 매우 만족스러웠습니다. 그동안 가르쳐주신 것 잊지않고 좋은 개발자로 살아가겠습니다! 너무 감사했습니다 :)  
    `,
  },
  {
    name: "장OO 님 ",
    type: "이직",
    info: "HIF 합격 / 연봉 5000 이상 / 연봉 42% 상승",
    projects: "https://github.com/f-lab-edu/awesome-wiki",
    tags: "#전공자 #이직 #연봉 5000 이상 #멘토링 기간 6개월",
    paragraph: `
   기술적인 부분 포함하여 커리어면에서도 물어보기 어려운 여러 의문점들에 대해 도움을 받았습니다. 
    `,
  },
  {
    name: "최OO 님 ",
    type: "1년차 이직",
    info: "펫프렌즈 합격 / SI -> IT서비스 기업 이직 / 연봉 34% 상승",
    projects: "https://github.com/f-lab-edu/event-recommender-festa",
    tags: "#비전공자 #경력자 #SI출신 #국비학원 출신 #멘토링 기간 11개월",
    paragraph: `
   처음 개발자가 되어볼까 생각했던건 전공으로 하던 일을 그만두고 나도 돈많이 벌고싶다는 생각과 인터넷에 있는 '비전공자도 XX에 갈수있다!' 같은 글들을 본 후였습니다.
<br/><br/>
하지만 실상은 많이 달랐습니다. 국비지원으로 6개월 교육받고는 SI밖에 갈곳이 없어서 일단 첫 회사에 입사는 했는데 입사 일주일만에 후회가 몰려오더라구요. 월급은 적고, 매일 야근에, 코드는 계속 복붙, 상사들의 연이은 퇴사, pom.xml파일이 뭔지도 모르는 개발자 출신 사장님....🤦‍♀️<br/>
인터넷에 있는 '6개월만에 서비스기업 가기'의 주인공들은 정말 손에 꼽을 정도고, 대단하기 때문에 같은글이 여러 사이트에서 돌고있는거라는것을 알았을 때는 이미 발을 빼기는 늦은 후였고, 일단 어떻게든 돈은 벌어야했으니 회사는 계속 다녔습니다.
<br/><br/>
그러다 동기의 추천으로 F-Lab에 대해 알게되었습니다. 처음엔 200도 안되는 월급으로 이 수업을 듣는게 맞을까 고민을 많이 했는데 수강 후기들을 보면서 몇달만 해보자는 생각에 시작했습니다.<br/>
학원처럼 강의를 듣고 알려주는게 아닌 스스로 공부하고, 계속 질문을 던져서 왜 그래야하는지 깊게 생각할 수 있게 하는 방식이라 처음에는 적응이 안됐습니다.<br/>
'왜 그렇게 될까요? 왜그렇게 생각하세요?' 등등..멘토님께서.. '왜'라는 질문을 하실때마다 화면 너머에서 저는.. 내가 지금 뱉은게 말인지 똥인지.. 방금 내가 무슨말을 한건지.. 이게 사람말이 맞는지.. 적응이 안됐는데, 몇달 하고보니 왜 이렇게 공부해야하는지 이해할수 있었습니다. 그리고 공부하다보니 몇달만 해볼까라는 생각이 사라지고 신청하길 잘했다는 생각이 들었습니다.
<br/><br/>
사실 멘토링의 수업방식은 의지가 약하면 공부를 안하게된다는 단점이 있지만, 일주일에 한번씩 있는 멘토님과의 수업시간, 중간중간 오는 메세지..포기하고 싶고 공부가 하기싫어도 멘토님께서 계속해서 채찍과 당근을 주시니 책 한장이라도 읽게 되고, 그게 쌓이니 지식이 되더라구요..
<br/><br/>
멘토링을 하기 전에는 내가 어떻게 개발자로 살아남아야하나 고민을 많이 했습니다.<br/>
하지만 멘토링 덕분에 주니어 개발자로 어떻게 살아가야하는지 많이 배울수 있었습니다. (물론 지금도 생각은 하고있지만..시니어가 되면 미래의 제가 잘할거에요...^^)<br/>
성실한 학생은 아니었고 옆에서 보기 속터지는 학생이었을거같긴 하지만 1년동안 포기하지않고 계속 이끌어주셔서 정말 감사합니다.^0^ (F-Lab의 철학2번을 보고 순살이 되었습니다...)
    `,
  },
  {
    name: "박OO 님 ",
    type: "신입",
    info: "김앤장 합격 / 연봉 4000 중반대",
    projects: "https://github.com/f-lab-edu/reservation-delegator",
    tags: "#전공자 #신입 #멘토링 기간 6개월",
    paragraph: `
   매주 기초부터 개발자로서 꼭 알아야 하는 필수 주제들을 다뤄주시며 탄탄한 수업들을 준비해주시고 개발 관련 질문들에 있어 성실하고 자세한 답변을 통해 백엔드 개발자의 기초와 기본을 많이 다져갈수 있었던 시간이었습니다. 또 멘토님께서 취업만을 위한 공부가 아닌 앞으로 개발자로서 앞으로의 커리어에 도움이 될 고급정보들도 함께 주셔서 느리더라도 믿음을 가지고 취업 준비를 할 수 있었다는 점이 좋았습니다. 앞으로도 멘토님께서 해주신 조언과 공부법 그리고 수업들을 복기 하며 개발자로서 더 큰 성장을 해 나가고 싶습니다. 
    `,
  },
  {
    name: "김OO 님 ",
    type: "3년차 이직",
    info: "웨이커 합격 / 연봉 4000 중반 / 연봉 27% 상승",
    projects: "https://github.com/f-lab-edu/conference-reservation",
    tags: "#전공자 #이직 #멘토링 기간 6개월",
    paragraph: `
   궁극적인 목표는 취업이었지만, 그 과정 속에선 개발자로서의 성장이 우선이었습니다.<br/>
보통 다른 부트캠프 기관들은 기간을 정해 놓고 취업 보장을 약속하며, 기간 별로 커리큘럼도 정해져 있습니다.<br/>
반면, f-lab 멘토링의 커리큘럼은 멘토와 멘티들이 협의를 통해 부족한 부분을 체크하며 현재 상황에 맞추어 매주 진도를 설정합니다.<br/>
처음에는 그 시간이 조금 답답하게 느낄 수 있지만, 그 시간들은 자신이 부족했던 부분을 보완할 수 있는 중요한 디딤돌 역할을 합니다.<br/>
이렇듯, f-lab도 멘티들의 취업이 목표이지만, 그 과정 속에서 멘티들의 개발자로서의 성장을 우선시 한다는 것을 느꼈습니다.<br/>
취업 성공을 하게 된 지금, 결국 제 선택과 f-lab의 멘토링 방식이 옳았다는 것을 확인할 수 있었습니다.<br/>
현재 개발자들의 공급과 시장의 수요의 간극을 메꾸기 위해 노력하고 있는 f-lab과 멘토님께 감사하다는 말씀 전합니다 
    `,
  },
  {
    name: "최OO 님 ",
    type: "신입",
    info: "교육 스타트업 입사",
    projects: "https://github.com/f-lab-edu/instagram-social-media",
    tags: "#전공자 #신입 #연봉 4000만원 이상 #멘토링 기간 6개월",
    paragraph: `
    대학교 시절에 개발 공부를 열심히 했지만, 네이버 인턴을 진행하면서 많이 부족하다는 것을 느꼈습니다. "공부를 열심히 했는데 왜 성과가 안 나온 걸까?" 고민을 했지만 명확하게 생각이 떠오르지 않아 답답하던 도중에 F-Lab에 대해서 알게 되어 멘토링을 진행했습니다.<br/><br/>
  
    멘토링을 진행하면서 공부 방향과 제가 구현한 코드 한 줄 한 줄 의미 있게 구현하도록 도와줘서 이때 많은 성장을 했습니다. 또한 제가 배운 내용을 멘토님과 이야기를 하면서 <strong><span class="highlight">제대로 공부하고 있는지 손쉽게 파악</span></strong>할 수 있었고 이를 기반으로 프로젝트에 적용해 이해도를 높였습니다.<br/><br/>
  
    개발 성장뿐만 아니라 <strong><span class="highlight">커뮤니케이션 스킬, 효율적인 업무 방식</span></strong>도 알려줘서 단순히 개발만 잘하는 것이 업무도 잘하는 개발자로 한 걸음 다가 갈 수 있었습니다.
    `,
  },
  {
    name: "김OO 님 ",
    type: "신입",
    info: "수백만이 사용하는 여행관련 어플리케이션 운영 스타트업 신입으로 취업",
    projects: "https://github.com/f-lab-edu/open-market-server",
    tags: "#SAFFY 출신 #비전공자 #신입 #멘토링 기간 6개월",
    paragraph: `
    <strong>
    <span class="highlight">멘토링을 하면서 느낀점은 이상적인 사수를 만난 느낌</span>이었습니다.<br/><br/>
    </strong>

    가장 좋았던 건, 리뷰를 통해 헷갈리거나 모르는 부분을 짚어주는데, 주입식으로 알려주는게 아니라 스스로 생각해서 목적지까지 도착하게 하는게 좋았습니다.
    `,
  },
  {
    name: "이OO 님 ",
    type: "신입",
    info: "쿠팡 백엔드 개발자",
    tags: "#전공자 #신입 #연봉 5000 이상",
    paragraph: `
    <strong>
    <span class="highlight">학습 방향성을 확실히 깨달을 수 있어서 좋았다.</span>
    </strong>

    취업 시장과 현직자들이 요구하는 능력을 학교에서만 공부하면 놓치게 되는데, 멘토링을 통해 이러한 간극을 매울 수 있게 되었다.
    `,
  },
  {
    name: "오OO 님 ",
    type: "신입",
    info: "금융 서비스 회사 취업",
    projects: "https://github.com/f-lab-edu/sns-agora",
    tags: "#비전공자 #신입 #초봉 4500만원 이상",
    paragraph: `
     F-Lab의 멘토링은 강의를 통해 기술 내용을 주입하는 방식 혹은 개발에 있어서 정답을 제시하는 교육이 아닙니다. <br/><br/>
    
     멘토링을 통해서 정답을 제시받기보다는 현재 상황에 적절한 답을 스스로 찾아내고, 학습할 수 있도록 방향성을 제시받을 수 있습니다. 이를 통해서 <strong><span class="highlight">스스로 공부하는 습관</span></strong>을 기를 수 있어서 개발자로서 성장하기에 좋은 기회가 되었다고 생각합니다.<br/><br/>
    
     또한 코드리뷰를 통해, 작성한 코드에 대해 다시 생각해보고, 수정을 거듭하면서 <strong><span class="highlight">간단한 코드 작성 시에도 고민을 하게 되는 습관</span></strong>을 기를 수 있었습니다.<br/><br/>
    
     개발자로서 성장하고자 하는 의지가 충만하고, 그만큼 자신의 시간을 학습에 투자할 수 있으신 분들이라면 F-Lab에서 많은 것을 얻어가실 수 있을 것이라고 생각합니다!
    `,
  },
  {
    name: "유OO 님 ",
    type: "신입",
    info: "IoT 기업 신입 취업",
    projects: "https://github.com/f-lab-edu/mytube",
    tags: "#국비학원 출신 #비전공자 #신입 #멘토링 기간 7개월",
    paragraph: `
    비전공자인 저는 어떤 공부를 해야 할지 막막했고 주변에 질문할 선배들조차 존재 하지 않았습니다. 이런 고민을 하던 중 f-lab을 알게 되었습니다.<br/><br/>
    
    이로써 저는 f-lab 멘토링을 통해서 공부의 방향성을 잡았을 뿐만 아니라 혼자서 삽질하며 머나먼 길을 돌아가지 않고 지름길로 들어서서 꾸준히 공부했다고 생각합니다.<br/><br/>
    
    멘토링을 진행할 때, 노력하는 만큼 저 자신의 역량을 더 쌓을 수 있었다고 생각합니다. 또한, 공부하거나 프로젝트 때 스스로 해결할 수 없는 부분을 멘토님에게 질문하여 혼자서 해낼 수 있는 능력을 배양할 수 있었습니다. 모르는 부분은 끝까지 알려고 노력하였고, 제 자신의 것으로 얻는 능력을 배울 수 있었습니다.<br/><br/>

    프로젝트를 할 때 피드백을 받으며 많이 배울 수 있었습니다. 제대로 선배 개발자에게 정확한 피드백을 받아 성장한다는 느낌을 받았습니다.
    
    <strong>
    개발자로써 역량 뿐만 아니라 많은 조언을 통해 습관적인 것들도 고칠 수 있었던 계기가 되었고 f-lab이 아니었으면 몰랐을 꿀팁을 많이 얻었습니다. 공부를 할 때, 항상 깊이 있게 공부하는 습관을 갖기 위해 노력 하였습니다. 이 깊이에 대해서 말하자면 <span class="highlight">'다른 누군가에게 설명할 정도까지 깊이 있게 공부해야 한다'</span> 는 것을 느껴 공부 습관도 고치는 계기가 되었습니다.<br/><br/>
    </strong>
    
    때로는 힘들 때 푸념도 말해가며 도움을 얻었으며 믿음으로 꾸준히 공부하여 결실을 볼 수 있었습니다. 마지막으로 인내심을 가지고 6개월동안 고시생 마인드를 가지고 달린다면 원하는 기업에 누구든지 입사할 수 있다고 생각합니다.
    `,
  },
  {
    name: "조OO 님 ",
    type: "3년 차 이직",
    info: "SI 회사에서 서비스 기업으로 이직",
    projects: "https://github.com/f-lab-edu/hi-bus-go",
    tags:
      "#연봉 30% 이상 상승 #비전공자 #경력자 #국비학원 출신 #멘토링 기간 7개월",
    paragraph: `
    <strong>
    개발자로 취업을 하면서 기술의 원리를 파악해서 공부하기 보다는 당장 구현에 먼저 치중하는 경우가 많았습니다. 그러다 보니 연봉 상승 및 이직이 뜻대로 되지 않았습니다. 연봉 상승 및 이직을 위해서는 이전과는 다른 공부 과정 및 동기부여가 필요하다고 느꼈습니다.<br/><br/>
    </strong>

    멘토링을 하면서 느낀건 좋은 개발자가 되는 과정이 쉽지 않다는 것을 느꼈습니다.

    <strong>
    <span class="highlight">개발자는 왜 꾸준히 공부를 해야되는지, 어떻게 공부해야되는지 많은 부분</span>에서 생각이 바뀐 시기였다고 생각합니다.<br/><br/>
    </strong>

    혼자 스스로 공부하며 어떤 방향으로 공부해야 하는지도 배울 수 있어서 좋았습니다.
    `,
  },
  {
    name: "남OO 님 ",
    type: "경력 1년 미만 이직",
    info: "SI 회사에서 금융 솔루션 회사 이직",
    projects: "https://github.com/f-lab-edu/delivery-service",
    tags: "#SI출신 #경력자 #비전공자 #멘토링 기간 6개월",
    paragraph: `
    <strong>
    <span class="highlight">개발자로써 어떻게 공부해야할지 방향성</span>을 알 수 있어서 매우 뜻깊은 시간이었습니다.
    </strong>
    `,
  },
  {
    name: "허OO 님 ",
    type: "경력 1년 미만 이직",
    info: "전산직에서 핀테크 서비스 회사로 이직",
    projects: "https://github.com/f-lab-edu/food-rocket",
    tags: "#멘토링 기간 12개월 #연봉 3000만원 중반 #비전공자 #경력자",
    paragraph: `
    <strong>
    아마 많은 취준생 혹은 주니어 개발자 분들이 '난 잘 하고 있는 건가' 에 대한 의문을 가질 거라고 생각합니다. 그리고 국비 지원, 코딩 학원, 멘토링이라는 여러 시스템을 통해 이러한 의문을 해소하고 싶을 겁니다. 저 역시 그랬으니까요:)<br/><br/>
    </strong>

    만약 멘토링을 받으면 모든 상황이 나아질 거고, 슈퍼 개발자가 되겠지 하는 생각을 가지셨다면 한 번 더 고민하셨으면 좋겠습니다. f-lab은 옆에서 숟가락으로 떠먹여주지 않습니다. 다만 숟가락을 쥐고 떠 먹는 방법을 알려줄 뿐입니다.<br/><br/>
    
    <strong>
    같은 멘토링을 받더라도 프로젝트 주제, 사용하는 기술, 진도 등 모든 것이 다릅니다. 좀 더 절박하고 많은 시간을 투자하시는 분이 더 많은 것을 얻으실 수 있을 겁니다. 저는 부족한 사람이지만 그럼에도 불구하고 많은 것을 얻을 수 있었습니다. 
    <span class="highlight">멘토링을 통해 좋은 개발자가 되는 방법을 배웠고 한 단계 성장할 수 있었습니다.</span>
    </strong>
    `,
  },

  {
    name: "신OO 님 ",
    type: "신입",
    info: "아이포트폴리오 합격",
    tags: "#비전공자 #신입",
    paragraph: `
    단순히 취업을 목표로 멘토링을 신청하였는데 취업을 넘어 인생 전반적으로 앞으로 어떻게 성장할 수 있는지를 배웠습니다.<br/><br/>

멘토링을 통해 미래의 불확실성에서 오는 불안감을 해소할 수 있었고 효율적인 방향으로 성장할 수 있다는 자신감을 얻었습니다.<br/><br/>

멘토링을 받기 전 주변에 개발 관련 지인이 없어 혼자서 무엇을 어떻게 준비해야할지 항상 고민이 있었습니다.
참고할 수 있는 자료는 아주 방대하지만, 이 정보들을 어떻게 걸러서 받아들이고 
여러 고민을 나의 상황에 맞게 어떻게 해결할 수 있을지, 지금 노력하는 것이 올바른 방향으로 향하는지
명쾌한 답을 찾기엔 어려웠습니다.<br/><br/>

처음엔 너무 기초적인 것만 공부하는 것이 아닌가? 너무 이론 위주로만 진행되는 건 아닌가? 왜 속 시원하게 답을 알려주지 않지?
하는 생각에 답답함과 걱정이 있었는데, 
지나고 보니 멘토님이 그렇게 하신 이유가 납득이 되더군요.<br/><br/>

멘토님께서도 간단하게 답을 바로 알려주실 수 있는 상황이었음에도 묵묵히 참으시고 방향성만을 제시함으로
문제를 스스로 해결해 나가는 것을 터득하게 해주셨다는 것을 느꼈습니다. 그리고 탄탄한 기본기를 갖춤으로 기술을 제대로 이해하고 활용하는 방법을 알려주고자 한 것 같습니다.<br/><br/>

매주 면접 형식으로 진행되면서 제대로 이해를 했는지 끝까지 파고드는 질문을 받고 답을 잘 못했을 땐 멘탈이 조금 나갈때도 있었지만,
내가 어느 정도는 알고 있다고 착각하는 것을 일깨워주고 또 자극받아 더 열심히 한 것 같고 또 스스로 공부하는 습관이 자연스럽게 체득되었습니다.
꾸준히 이력서 업데이트와 취업에 대한 가이드 및 케어도 이후 취준 기간에 아주 큰 도움이 되었습니다.<br/><br/>

수준 높은 멘토로부터 받는 코드리뷰와 개발자 인생에 대한 솔직한 조언과 개인의 상황에 맞는 피드백은 어디에서도 얻을 수 없는 F-Lab의 장점이라고 생각합니다.
    `,
  },
  {
    name: "김OO 님 ",
    type: "경력",
    info: "대기업SI -> 네이버 클라우드 이직",
    tags: "#경력자 #전공자 #네카라쿠배 합격 #대기업 합격",
    paragraph: `
    혼자서 공부했을 때에는 깊게 공부하지 못하고 일정 범위 이상 넘어가기 어려웠었는데, 멘토링을 하면서 그 벽을 넘어선 느낌이였습니다.
    `,
  },
  {
    name: "박OO 님 ",
    type: "신입",
    info: "카카오 본사 합격",
    tags: "#신입 #전공자 #네카라쿠배 합격 #대기업 합격 #연봉 5000 이상",
    paragraph: `
    평소 개발을 할 때, 구글에서 코드를 복붙하여 구현하는 것에만 집중하였고 왜 그런지, 이유가 무엇인지에 대해 궁금해하지 않았습니다. 새로운 것에 대해 아는 것이 두려웠고 저의 두려움을 자신감으로 바꿔 줄 좀 더 나은 환경과 좋은 스승이 있었으면 좋겠다는 생각에 F-LAB을 시작하게 되었습니다.<br/><br/>

멘토링을 진행하면서 멘토님에게 질문을 받으며 문제에 대한 이유를 생각하는 연습을 하게 되었고, 다양한 기술들을 어떤 식으로 공부를 진행해야 하는지에 대한 방향도 알게 되었습니다.<br/>
특히 회사 면접을 볼 때, 대부분이 멘토링 시간 때 멘토님이 질문했던 것들이라 대답을 잘 할 수 있었던 것 같습니다.<br/><br/>

처음 시작할 때, 금전적 부담이 많이 되어 멘토링을 시작할지 많이 고민하였지만, 멘토링 하는 동안 그 이상의 가치를 얻을 수 있다고 생각합니다.
    `,
  },
  {
    name: "남OO 님 ",
    type: "신입",
    info: "퓨쳐위즈 합격",
    tags: "#신입 #전공자 #연봉 4000 이상",
    paragraph: `
     멘토링을 시작할 당시에는 6개월 전에 대충 인강을 보면서 간단한 게시판을 구현해본게 전부였기 때문에 flab을 6개월 수강하고 과연 내가 개발자로 취직할 수 있을까하는 의구심이 있었습니다. 하지만 저는 멘토링을 진행하고 나서 운 좋게 제가 예상했던 것보다 좋은 회사에 좋은 조건으로 입사할 수 있었습니다.
정말 단연코 flab이 아니었다면 이루지 못했을 성과라고 생각해요.<br/><br/> 
물론 공부했던 기간이 힘들고 어려웠지만 멘토님의 가이드가 있었기에 해낼 수 있었습니다. 
시간이 지나서 더 멋진 개발자가 되어 다시 만나뵙게 되길 바랍니다.😃 
    `,
  },
  {
    name: "김OO 님 ",
    type: "신입",
    info: "트레바리 합격",
    tags: "#신입 #전공자",
    paragraph: `
     저는 멘토링을 통해 개발자로서 가져야할 태도에 대해 배운 것 같습니다. 
어떤 기술을 사용할 때, 합당한 이유를 고민하게 되고, 다른 대안은 없었는지? 트레이드-오프에 대해 고민하는 습관을 기를 수 있었습니다. 
즉 개발에 정해진 답이 있다기 보단 상황에 따라 적합한 선택지가 있고, 개발자는 이를 위해서 깊이있는 학습이 기본이 되어야 한다는 점을 배웠습니다.<br/><br/> 

또 다른 좋았던 점은 매 시간이 기술면접 같아서 자연스럽게 면접대비가 된다는 점입니다.
멘토링전에 면접을 봤을 땐 너무 긴장되서 아는 것도 잘 대답하지 못하고, 아는 것을 면접에서 잘 얘기하는 것도 어려웠습니다. 그러나 멘토링 후에는 자연스럽게 연습이 되어서 실전에서는 편안하고 자신감있게 면접을 볼 수 있었던 것 같습니다.<br/><br/> 

멘토링 전/후를 비교해보면, 제가 볼 수 있는 시야가 많이 넓어졌다는 생각이 듭니다. 
혼자라면 생각해볼 수 없던 고민거리를 멘토님이 계속해서 던져주셨고, 이를 바탕으로 한단계 성장할 수 있었습니다. 그리고 이제는 이러한 고민거리를 혼자서도 찾을 수 있게 된 것 같고,  앞으로도 더 성장할 수 있는 방법을 깨우친 것 같습니다.<br/><br/> 

감사합니다.
    `,
  },
  {
    name: "최OO 님 ",
    type: "SI -> 서비스 회사 이직",
    info: "Compax 합격",
    tags: "#경력자 #비전공자 #SI출신 #연봉 5000 이상",
    paragraph: `
     독학으로 si회사에 늦깎이 신입으로 입사를 했었습니다. 주말부부로 지내며, 잦은 야근을 하던중에 에프랩을 알게 되었고 에릭멘토님을 만났습니다. <br/><br/>부족한 시간 가운데 시간을 또 만들어 멘토님이 제시한 공부방향에 따라 공부를 했습니다. 덕분에 막연하게 느끼던 목표에 더 가까워졌습니다. 
    `,
  },
  {
    name: "정OO 님 ",
    type: "신입",
    info: "퓨처위즈 (두나무 자회사) 합격",
    tags: "#신입 #전공자#연봉 4000 이상",
    paragraph: `
     개발 또는 인생에서 어떤 문제가 주어졌을 때,<br/><br/>

1. 스스로 문제를 정의하고 해결하는 능력<br/><br/>

2. 설령 오늘 문제를 해결하지 못했더라도 "내일은 무조건 해결하고 말겠다" 라는 끈기와 긍정적인 마인드<br/><br/>

3. 문제를 정의하고 해결한 과정을 다른 개발자에게 설명할 때, 내 의도를 더욱 명확히 전달하는 것과 상대방의 기분이 나쁘지 않게 의도를 전달하는 것<br/><br/>

들에 관해 멘토링을 통해서 많은 조언을 얻고 배웠습니다.<br/><br/>

현재도 많은 고민들이 있지만, 멘토링을 통해 얻은 여러 경험들을 토대로 계속 답을 찾아가고 있습니다. 혹시나 이 글을 보시는 분이 있다면 포기하지말고 꼭 답을 찾아가시길 기원합니다.  
    `,
  },
  {
    name: "안OO 님 ",
    type: "경력",
    info: "여기어때 합격",
    tags: "#경력자 (1년) #비전공자 #국비학원 출신 #SI출신 #연봉 4000 이상",
    paragraph: `
     개발 커리어의 다방면에서 도움을 받았기에 절대 후회 없는 선택이었습니다. 개발 지식에 필요한 서적이나 자료부터 시작해 지식 습득 방식, 장기적 관점에서 개발자가 가져야 할 태도 등 앞으로 혼자 개척해나갈 힘을 기르게 해준 귀중한 경험이었습니다. <br/><br/>
     
     회사를 다니면서 병행하느라 진도가 더뎌지고 자신에 대한 의구심이 들 때도 잘 이끌어 주신 덕분에 여기까지 올 수 있었던 것 같습니다. 지금 상황에 그치지 않고 계속 정진하는 개발자가 되자고 마음 다짐을 한데에는 멘토님의 영향이 컸습니다. 지속적으로 멘토, 멘티 분과 교류하면서 다같이 좋은 개발자로 발전해 가면 좋겠습니다.
    `,
  },
  {
    name: "이OO 님 ",
    type: "경력",
    info: "금융권SI -> 카카오 모빌리티 합격",
    tags:
      "#경력자 #비전공자 #SI출신 #네카라쿠배 합격 #대기업 합격 #연봉 5000 이상",
    paragraph: `
     우선 f-lab 멘토링을 알게되고 참여할 수 있어서 영광이었습니다. <br/>
다른 멘토분들도 각기 다른 훌륭한 방식으로 멘티분들을 지도해주셨겠지만 제 멘토님이셨던 bright님의 멘토링 방식이 인상깊었습니다.<br/>
이전에 여러 개발관련 여러 교육도 받아보고 프로그램도 들었는데 단순한 지식전달뿐이었습니다.<br/>
하지만 f-lab 멘토링은 개발자로서 롱런하기 위한 생각의 그릇을 키워주고 오히려 상황에 맞는 질문을 던지면서 스스로 고민하게끔 한다는 점에서 신선했습니다.<br/>
단순하게 좋은 회사로 취직/이직하게끔 해주는 것이 아니라 생각하는 개발자가 되도록 도와주는 좋은 프로그램이라고 생각합니다. 스스로 더 좋은, 능력있는 개발자가 되고자하는 마음이 간절한 분들에게 적극 추천하고 싶습니다. :)
    `,
  },
  {
    name: "이OO 님 ",
    type: "경력",
    info: "11번가 합격",
    tags: "#경력자 #7년차 #SI출신",
    paragraph: `
     지금까지 업무를 하는데 모르는 내용은 검색하면서 진행했습니다. 업무는 진행이 되지만, 전반적인 지식이 많이 부족했습니다. 열심히 공부는 하지만 무엇을 해야 할지 몰랐습니다.<br/>
멘토링을 하면서 공부하는 습관과 공부 하는 방법을 많이 배웠습니다. 멘토링 진행한 것은 앞으로 저의 개발에 전환점이 되었습니다. 7년 실무에서 배운 경험보다 멘토링을 하면서 공부한 5개월이 더 다양한 지식을 쌓은 것 같습니다.<br/>
저와 같이 경력이 쌓이지만 그만큼 실력이 쌓이지 않고 실력이 늘지 않는다고 생각하는 사람들에게 정말 필요하다고 생각합니다. 또한 신입분들도 시작부터 좋은 멘토를 만나서 시작하는 것은 엄청난 행운이라고 생각합니다. <br/>
개발자의 연봉이 많이 올라갔고, 그만큼 회사에서는 다양한 지식을 가진 개발자를 원하게 되었습니다. 예전과 달리 경력이 있다고 좋은 곳을 가는 시대는 사라졌습니다. 다들 열심히 공부하지만, 좋은 공부법으로 공부했으면 좋겠습니다.
    `,
  },
  {
    name: "윤OO 님 ",
    type: "경력",
    info: "네이버 재직 중 성장을 위한 멘토링 진행",
    tags: "#경력자 #전공자",
    paragraph: `
     F-lab 멘토링은 개발자로 성장할 수 있도록 방향을 제시해 줍니다.<br/><br/>

저의 경우는 F-lab을 통해 네이버에 들어가게 된 사례는 아닙니다. 입사와 멘토링 시작일이 거의 같았거든요. 그런데도 제가 멘토링을 진행한 이유는 지금보다 더 훌륭한 개발자가 되고 싶은 욕망이 있었고 미래에 대해 막막함이 있었기 때문입니다.<br/><br/>

멘토링을 진행하면서 새로운 기술을 깊이 배워가는 과정, 깊이 고민하는 과정을 많이 배웠고 구글링과 복사 & 붙여 넣기를 하는 개발자를 넘어 생각하는 개발자가 될 수 있었습니다.<br/><br/>

취준생분들의 후기는 다른 분들이 많이 써주셨지만, 저와 같이 좋은 IT 회사에 다니시는 분이라도 더 성장하고 싶고 진정한 개발자가 되기 위해서라면 하루빨리 멘토링을 추천해 드립니다.
    `,
  },
  {
    name: "송OO 님 ",
    type: "경력",
    info: "Class 101 합격",
    tags: "#경력자 #전공자 #연봉 5000 이상",
    paragraph: `
     웹서비스로 전향하기 위한 백엔드 프로젝트 설계 기술 및 공부 방향에 대해 많은 것을 배웠습니다. 혼자서 독학했었으면 공부의 방향이 잘못 되었을텐데 F-LAB 멘토님을 통해 학습의 방향을 설정 할수 있었습니다.
    `,
  },
  {
    name: "양OO 님 ",
    type: "경력",
    info: "네이버 웹툰 합격",
    tags: "#경력자 #전공자 #연봉 5000 이상 #네카라쿠배 합격 #대기업 합격",
    paragraph: `
     특정 기술에 대해 단순하게 사용하고 끝내는 것이 아니라, 그 기술은 내부적으로 어떤 동작 방식을 가졌는지 호기심을 갖고, 주도적으로 고민할 수 있는 힘을 기를 수 있게끔 방향성을 제시해 주셔서 좋았습니다.<br/>
또한, 멘토님께서 현재 내가 어떤 위치에 있고, 원하는 회사에 지원해볼 만한 실력인지 객관적으로 파악해 주셔서 자신감 있게 도전할 수 있었습니다.<br/>
그리고 이전에 원하는 기업에 지원할 때마다 서류에서 떨어지는 일이 다반사였는데, 멘토님께서 같은 내용을 갖고도 나의 기술력과 경험을 더 잘 어필할 수 있게 이력서를 작성하는 여러 가지 노하우를 알려주셔서 서류 합격률도 크게 높일 수 있었습니다.<br/>
또한, 수업시간마다 직접 공부해온 내용을 바탕으로 마치 면접을 보는 것처럼 수업을 진행해 주셔서, 실제 면접을 대비하기에도 수월했습니다.<br/>
본인이 노력한 만큼 성장할 수 있는 환경을 만들어 준 F-lab과 멘토님께 감사하고, 정말 어디서도 얻기 힘든 값진 경험이었습니다.
    `,
  },
  {
    name: "정OO 님 ",
    type: "경력 (3년)",
    info: "SI -> 서비스회사 이직",
    tags: "#비전공자 #경력자 #SI출신 #국비학원 출신",
    paragraph: `
     SI회사에서 자사 서비스를 운영하는 회사로 커리어 전환을 하고 싶던 차에 f-lab을 알게 됐습니다. 좋은 멘토님을 만나서 기술을 학습함에 있어서 올바른 방식과 마인드를 배울 수 있었던 것 같습니다. 감사합니다!
    `,
  },
  {
    name: "양OO 님 ",
    type: "신입",
    info: "데브시스터즈 합격",
    tags: "#전공자 #신입 #연봉 5000 이상",
    paragraph: `
     저는 멘토링을 통해서 개발자로서 밑바탕을 그릴 수 있었습니다. <br/><br/>
 먼저, 기술을 공부하는 저의 태도를 바꾸었습니다. 멘토링을 통해서 하나의 기술을 알더라도 제대로 알고 사용하는 것이 중요하다는 것을 알게 되었고, 기술을 더 꼼꼼히 공부하는 습관이 생겼습니다. 멘토링을 하면서 만든 공부 습관이 앞으로 새로운 기술을 습득할 때도 도움이 될 것 같습니다.<br/>
 또, 나만의 글쓰기 습관을 만들 수 있었습니다. 블로그를 제대로 사용하기 위해서 어떤 글을 쓸지 막막했는데, 멘토님께서 남들과 똑같은 글이 아니라 나만의 글을 쓰기 위해서 어떻게 해야하는지에 대해서 조언해주셨고, 제 글에 대해서 피드백을 받으면서 나만의 글을 쓰는 방식을 알게 되었습니다.<br/>
 마지막으로, 멘토님께서 어디서도 듣기 힘든 인사이트를 공유해주셔서 정말 큰 도움이 되었습니다. 개발 외적으로도 커리어 조언, 연봉 협상 등 다양한 꿀팁들을 얻을 수 있어서 좋았습니다.<br/><br/>

긴 시간 동안 제게 소중한 가르침을 주신 멘토님께 너무 감사드립니다 :)<br/>
에프랩에서의 경험을 발판 삼아서, 저도 멘토님 같은 뛰어난 개발자로 성장하겠습니다!!
    `,
  },
  {
    name: "이OO 님 ",
    type: "경력",
    info: "C++ -> IT서비스 회사로 이직",
    tags: "#전공자 #경력자 #연봉 5000 이상",
    paragraph: `
     공부 방향과 컨텐츠, 면접 준비에 많은 도움이 되어 감사합니다
    `,
  },
  {
    name: "박OO 님 ",
    type: "경력 1년 이직",
    info: "솔루션회사 -> AB180 합격",
    tags: "#경력자 #전공자",
    paragraph: `
    <strong>
    좋았습니다. 신선했구요. 시야가 더 넓어졌어요.
    </strong>
    `,
  },
  {
    name: "김OO 님 ",
    type: "멘토링 수강 중",
    tags: "#전공자",
    paragraph: `
    막연하게 잘하고 싶다라는 생각에서 구체적으로 어떤 방향으로 학습을해야 좋은 개발자가 될 수 있는지에 대해 알 수 있어서 좋았습니다.<br/><br/>

    <strong>
    단순히 '이런 방식으로 하세요' 가 아닌 '이런 방식을 선택해야 하는 이유' 에 대해서도 고민해볼 수 있어서 <span class="highlight">멘토링이 끝난 이후에도 혼자 자립하여 학습</span>할 수 있도록 도와주셔서 정말 좋았습니다.
    </strong>
    `,
  },
  {
    name: "익명 ",
    type: "1",
    paragraph: `
    근무 환경 탓에 항상 복사 붙여넣기만 급급했었는데 멘토링을 받을 때 만큼은 깊은 생각을 하며 개발하게 되는 나를 볼 수 있었습니다.<br/><br/>

    <strong>
    그리고 <span class="highlight">업무에 있어 커뮤니케이션 스킬이 뭔지, 효율적으로 일하는 방법이 뭔지</span>에 대해 많이 알아갈 수 있어서 좋았습니다.<br/><br/>
    </strong>
    
    회사와 멘토링을 많이 비교하게 될 수 밖에 없는데, 코드 리뷰의 중요성을 매일(정말 절실히) 깨닫는 중이고 점차 재미를 붙여가고 있습니다. 멘토님께서 조금 더 여력이 되신다면 리뷰를 더 많이 달아주시면 좋을 것 같습니다.(리뷰를 많이 얻으려면 제가 더 열심히, 더 깊은 생각을 해야겠죠..? ^^ 항상 열심히 하겠습니다!!! ㅎㅎ)
    `,
  },
  {
    name: "익명 ",
    type: "2",
    paragraph: `
    <strong>
    <span class="highlight">새로운 관점을 제시하고 알고 있다고 넘겨 짚는 기능에 대해 한 번 더 고민하도록 알려주시는 멘토링</span> 덕분에 많은 걸 배우고 있습니다. 어떠한 얘기에도 차근차근 알려주셔서 제 페이스대로 배울 수 있는 점이 제일 좋았습니다.
    </strong>
    `,
  },
];
