export default [
    {
        type: '두번째',
        desc: '협업을 통한 코드리뷰 문화',
        imgUrl: [
            require('@/assets/images/design/codeReview01.png'),
            require('@/assets/images/design/codeReview02.png')
        ]
    },
    {
        type: '세번째',
        desc: '매일 매일 자극 받고 동기부여  되는 분위기',
        imgUrl: [
            require('@/assets/images/design/slack01.png'),
            require('@/assets/images/design/slack02.png')
        ]
    }
]
