export default [
  {
    startDate: "2022-11-01",
    endDate: "2022-10-20",
    maxCount: 10,
    currentCount: 4,
    discountPrice: 0,
    salePrice: 8000000,
    earlybird: false,
    disabled: false,
    notOpened: false,
    openSale: false,
  },
  {
    startDate: "2022-12-01",
    endDate: "2022-11-20",
    maxCount: 10,
    currentCount: 3,
    discountPrice: 240000,
    salePrice: 7760000,
    earlybird: true,
    disabled: false,
    notOpened: false,
    openSale: false,
  },
  {
    startDate: "2023-01-01",
    endDate: "2022-12-20",
    maxCount: 10,
    currentCount: 3,
    discountPrice: 400000,
    salePrice: 7600000,
    earlybird: true,
    disabled: false,
    notOpened: false,
    openSale: false,
  },
];
