export default [
  {
    name: "Kevin",
    info: ["- 카카오스타일 출신", "- 야놀자 출신"],
    tag: "프론트엔드",
  },
  {
    name: "Thesis",
    info: ["- 오늘의집 출신", "- 카카오 출신"],
    tag: "프론트엔드",
  },
  {
    name: "Promm",
    info: ["- 당근마켓 출신", "- 라인 출신"],
    tag: "프론트엔드",
  },
  {
    name: "Noah",
    info: [
      "- 마이리얼트립 출신",
      "- 지그재그(카카오스타일) 출신",
      "- 직방 출신",
      "- 다양한 프론트엔드 멘토링 경험",
    ],
    tag: "프론트엔드",
  },
  {
    name: "Zero",
    info: ["- 당근마켓 출신"],
    tag: "프론트엔드",
  },
  {
    name: "Sophia",
    info: ["- 뱅크샐러드 출신"],
    tag: "프론트엔드",
  },
];
