




























































































































































































































































































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      registerUrl: process.env.VUE_APP_REGISTER_URL,
      pblCourseUrls: [
        "https://board.f-lab.kr/backend-pbl",
        "https://board.f-lab.kr/pbl-backend",
      ],
      pblCourseUrl: "https://board.f-lab.kr/backend-pbl",
      techBlogUrl: "https://f-lab.medium.com",
    };
  },
  methods: {
    goToLogin() {
      this.$analytics.logEvent("click_user");
      window.open(
        `${
          this.registerUrl
        }?related_device_id=${this.$analytics.getAmplitudeDeviceId()}`
      );
    },

    goToBlog() {
      this.$analytics.logEvent("click_blog");
    },
    goToCompanyEdu() {
      this.$analytics.logEvent("click_company_edu");
    },
    goToRecruitPartner() {
      this.$analytics.logEvent("click_recruit_partner");
    },
    clickHamburger() {
      this.$analytics.logEvent("click_hamburger");
    },
    goToFrontMentoring() {
      this.$analytics.logEvent("click_frontend_mentoring");
    },
    goToPythonBackendMentoring() {
      this.$analytics.logEvent("click_python_backend");
    },
    goToMoGakCo() {
      this.$analytics.logEvent("click_mogakco");
    },
    goToMembership() {
      this.$analytics.logEvent("click_membership");
    },
    goToNotice() {
      this.$analytics.logEvent("click_notice");
    },
    goToIos() {
      this.$analytics.logEvent("click_ios");
    },
    goToResume() {
      this.$analytics.logEvent("click_resume");
    },
    goToRecommendCompany() {
      this.$analytics.logEvent("click_recommend_company");
    },
    goToNewsletter() {
      this.$analytics.logEvent("click_newsletter");
    },
    goToPblCourse() {
      this.$analytics.logEvent("click_pbl");
    },
  },
  created() {
    if (localStorage.pbl_num && this.pblCourseUrls[localStorage.pbl_num]) {
      // ignore
    } else {
      localStorage.pbl_num = Math.floor(Math.random() * 2);
    }
    this.pblCourseUrl = this.pblCourseUrls[localStorage.pbl_num];
  },
});
