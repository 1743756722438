export default [
  {
    heading:
      "상시로 멘토의 도움을 받을 수 있어 지속적으로 학습에 집중할 수 있습니다.",
    desc: `F-Lab은 유명 IT기업 출신에 실력과 경험까지 갖춘 멘토가 개발 지식뿐 아니라 높은 수준의 포트폴리오, 이력서 작성, 면접스킬까지 모든 것을 매니징해드립니다. 또한 많은 인원을 한꺼번에 교육하는 것이 아니라 한 팀에 2명씩, 정해진 인원만 멘토링하기 때문에 개인의 상황에 맞춘 세밀한 케어를 받으실 수 있습니다. <br/><br/>
          또한 정해진 멘토링 시간뿐만이 아니라 메신저를 통해 상시로 멘토님에게 기술, 커리어적인 조언요청 등 상시로 피드백을 요청할 수 있으며, Github를 통해 상시로 코드리뷰를 제공하기 때문에 빠른 성장이 가능합니다.`,
  },
  {
    heading:
      "상위 1% 개발자인 멘토의 경험을 전수받으며 깊게, 제대로 공부합니다.",
    desc: `시장에 있는 많은 개발자들은 서비스를 구현하는 수준으로 보통 개발을 진행합니다. 하지만 F-Lab은 단순히 서비스를 만드는 것에 그치지 않고, 더 깊은 곳을 보고 설계할 수 있는 개발자로 성장하는 것을 추구합니다.
          <br/><br/>
          클라우드 컴퓨팅 기술의 발전과 마이크로 서비스 패러다임의 확산에 따라 개발자는 더 크게 보고 확장성,성능을 생각하며 설계할줄 알아야하고, Devops가 발전함에 따라 개발-통합-배포-운영에 대한 지식을 갖춰야합니다. 대용량 트래픽을 발생시키고, 이를 모니터링해 더 높은 성능 및 확장성,설계,튜닝을 할 수 있게 하고, CI/CD,코드 리뷰 등을 진행해 일반적인 현직 개발자보다 더 많은 지식을 쌓게 합니다.
          <br/><br/>
          개발 프로세스에 대한 전반적인 것들을 관리해드리기에 하나를 익힐 때마다 다음 스텝을 계속 제시해드리면서 끝 없이 깊은 지식을 쌓을 수 있도록 합니다. 실제 현직 개발자들도 현재 F-Lab에서 멘토링을 받으며 많은 것을 배우고 있다는 평을 주셨으며, 더 좋은 대우와 복지를 가진 회사로 이직에 성공하고 있습니다.`,
  },
  {
    heading: "노력해야할 방향을 제시해 드립니다.",
    desc: `세상에는 노력하는 사람들이 정말 많습니다. 하지만 그 사람들이 모두 성공하는 것은 아닙니다. 그 이유는 노력의 양도 중요하지만 노력의 방향이 더 중요하기 때문입니다. 마찬가지로 우리나라에서 의욕도 있고 공부를 열심히 하는 개발자는 많지만 노력의 가성비가 나오지 않는 경우가 많습니다.
          <br/><br/>
          이유는 본인이 더 성장하기 위해 어느 방향으로 어떤 것을 공부해야하는지 잘 모르기 때문입니다. F-Lab은 개발 실력, 커뮤니케이션 능력을 겸비한 경험 많은 개발자가 직접 코드리뷰와, 공부 방향, 이력서, 블로그 등 다방면의 방향을 잡아드려 올바른 방향으로 빠르게 성장할 수 있습니다.
          <br/><br/>
          연봉상승은 복리이기에 100만원을 들여서 연봉을 100만원 올릴 수 있다면 좋은 투자로 볼 수 있습니다. 멘토가 겪어온 모든 경험과 노하우를 전달하여 최대한의 연봉을 받을 수 있게 도와드립니다.
          <br/><br/>
          자신의 강점을 이력서에 피력하고 면접에서 증명하는 것은 매우 중요합니다. 아무리 개발을 잘 하더라도 이를 어필하지 못하면 못하는 것과 같으며, 똑같은 기술을 쓰더라도 어휘와 표현력에 따라 면접관에게 훨씬 큰 임팩트를 줄 수 있습니다. 이러한 점들을 멘토링을 통하여 잘 전수해드리겠습니다.
          <br/><br/>
          F-Lab은 멘토가 질 높은 포트폴리오를 만들 수 있게 조언하고, 멘토가 직접 면접 시나리오를 짜서 모의 면접을 하며 멘티가 기술면접에 잘 대처하고 좋은 평가를 받을 수 있도록 도와드립니다.`,
  },
  {
    heading:
      "멘토링이 끝나도 성장할 수 있도록 ‘지식’보다 ‘지식을 얻는 방법’을 알려드립니다.",
    desc: `개발자는 평생 공부해야하는 직업입니다. 지식을 강의로만 주입받는 것은 깊이에 한계가 있고, 이는 즉 자신의 전문성을 기르는데에 한계를 만들어냅니다.
          <br/><br/>
          더 깊은 부분을 고려하려면 책이나 공식 도큐먼트를 읽어야 하고, 이를 깊게 이해하려면 CS의 기본 지식이 필요합니다. 이런 학교에서 공부하기 지겨웠던, 혹은 배우지 못했던 CS지식과 다른 여러 깊은 지식들을 공부해야하는 이유를 실제 프로젝트에서 느끼게 함으로써 여러 지식들이 "왜" 필요한지 체감하면서 공부하도록 유도합니다.
          <br/><br/>
          프로젝트를 진행할 때에도 구현 방법을 알려드리는게 아닌, 문제를 해결하기 위한 방법들을 찾고, 그 방법들의 장단점을 고려해 최선의 선택을 하는 트레이드오프를 고려한 개발을 하는 방법을 알려드립니다.`,
  },
  {
    heading:
      "주입식으로 강의받는 것이 아니라 질문을 통해 생각하는 능력을 길러드립니다.",
    desc: `보통 개발 강의를 들으면 어떤 기술을 이용해 무언가를 만들어보는 것을 하게 되고, 단순히 구현만 하는 것이기 때문에 그냥 시키는대로 따라하면 어느 정도는 완성을 할 수 있습니다.
          <br/><br/>
          다시 생각해보면 누구든 만들 수 있는 것이라면 그 것은 회사에 지원할 때 본인의 강점으로 어필할 수 있는 것일까요?
          <br/><br/>
          본인의 강점으로 어필하려면 아무나 할 수 없는 것을 해야합니다. 단순히 뭔가를 만들어본 프로젝트 100개보다 이런 아무나 할 수 없는 것을 적용한 프로젝트 하나가 면접에서 더 많은 기술적인 대화를 만들어내기에 좋습니다.
          <br/><br/>
          F-Lab에서는 국내, 국외의 최고 IT기업들에 근무했고, 엄격한 검증을 거친 멘토가 본인의 경험을 토대로 이런 아무나 할 수 없는 것들의 존재에 대해 알려주고, 이론을 바탕으로 다양한 비즈니스 요구사항과 대용량 트래픽 환경에서 추론하며 개발하실 수 있도록 가이드해드립니다.`,
  },
  {
    heading: "100% 온라인으로 진행합니다.",
    desc: `화상회의 솔루션을 통해 상담부터 멘토링 진행, 모의 면접, 취업/이직 지원까지 100% 온라인으로 진행하기에 이동 시간의 낭비 및 지역의 제한이 없습니다.
          <br/><br/>
          실시간 화면 공유를 통한 피드백으로 오프라인보다 더 빠르고 정확한 정보 전달이 가능합니다.`,
  },
];
