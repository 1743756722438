export default [
  {
    startDate: "2022-11-01",
    endDate: "2022-10-20",
    maxCount: 10,
    currentCount: 4,
    discountPrice: 2000000,
    salePrice: 6000000,
    earlybird: false,
    disabled: false,
    notOpened: false,
    openSale: false,
  },
  {
    startDate: "2022-12-01",
    endDate: "2022-11-20",
    maxCount: 10,
    currentCount: 3,
    discountPrice: 2180000,
    salePrice: 5820000,
    earlybird: true,
    disabled: false,
    notOpened: false,
    openSale: false,
  },
  {
    startDate: "2023-01-01",
    endDate: "2022-12-20",
    maxCount: 10,
    currentCount: 2,
    discountPrice: 2300000,
    salePrice: 5700000,
    earlybird: true,
    disabled: false,
    notOpened: false,
    openSale: false,
  },
];
