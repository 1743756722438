import Vue from 'vue';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import amplitude from 'amplitude-js';

export class AnalyticsDelegator {
  // field
  // app;
  // firebase;
  // amplitude;

  constructor(relatedDeviceId = undefined) {
    amplitude.getInstance().init(
      process.env.VUE_APP_AMPLITUDE_API_KEY,
      null,
      {
        deviceId: relatedDeviceId,
        includeFbclid: true,
        includeGclid: true,
        includeReferrer: true,
        includeUtm: true,
      },
      (instance) => {
        this.amplitudeDeviceId = instance.options.deviceId;
      },
    );
    this.amplitude = amplitude.getInstance();

    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    };

    const app = initializeApp(firebaseConfig);
    this.firebase = getAnalytics(app);

  }

  async logEvent(event, properties) {
    this.amplitude.logEvent(event, properties);
    logEvent(this.firebase, event, properties);
  }

  async setUserProperties(properties) {
    this.amplitude.setUserProperties(properties);
    setUserProperties(this.firebase, properties);
  }
  
  getAmplitudeDeviceId() {
    return this.amplitudeDeviceId;
  }
}

function getUrlParams() {     
  var params = {};  
  
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    (str, key, value) => {
      params[key] = value;
    }
  );
  
  return params; 
}

const relatedDeviceId = getUrlParams().related_device_id;

Vue.prototype.$analytics = new AnalyticsDelegator(relatedDeviceId);
