export const backEndGrowth = [
    {
        title: '현직 백엔드 개발자',
        list: [
            "코드 리뷰를 제대로 하지 않는 회사에 다니고 있다",
            "깊은 수준의 지식을 적용하기 힘든 개발 업무 환경이다",
            "실력 있는 사수를 만나지 못해 제대로 된 피드백을 받지 못하고 있다",
            "기술을 사용만 하고 왜 쓰는지에 대해서는 답을 못한다",
            "성장하기 위해서 무엇을, 어떻게 공부해야 하는지 모른다"
        ]
    },
    {
        title: '취업을 준비하는 대학생',
        list: [
            "기술을 사용만 하고 왜 쓰는지에 대해서는 답을 못한다",
            "학습한 이론을 실무에 어떻게 접목해야 하는지 모르고 있다",
            "실력 있는 개발자를 만나보기 힘들다",
            "학생끼리 교류하니 제대로 된 정보를 얻기 힘들다",
            "서비스 운영에서 정말 고민해야 하는 부분이 어떤 부분인지 모른다"
        ]
    }
]

export const androidGrowth = [
    {
        title: '현직 안드로이드 개발자',
        list: [
            "코드 리뷰를 제대로 하지 않는 회사에 다니고 있다",
            "기술적인 체계화가 제대로 안 되어 있다",
            "실력 있는 사수를 만나지 못해 제대로 된 피드백을 받지 못하고 있다",
            "기술을 사용만 하고 왜 쓰는지에 대해서는 답을 못한다",
            "성장하기 위해서 무엇을, 어떻게 공부해야 하는지 모른다"
        ]
    },
    {
        title: '취업을 준비하는 대학생',
        list: [
            "앱을 만들기만 할 줄 안다",
            "어떻게 해야 앱을 '잘' 만드는 줄 모르다",
            "실력 있는 개발자를 만나보기 힘들다",
            "학생끼리 교류하니 제대로 된 정보를 얻기 힘들다",
            "서비스 운영에서 정말 고민해야 하는 부분이 어떤 부분인지 모른다"
        ]
    }
]

export const frontendGrowth = [
    {
        title: '현직 프론트엔드 개발자',
        list: [
            "코드 리뷰를 제대로 하지 않는 회사에 다니고 있다",
            "깊은 수준의 지식을 적용하기 힘든 개발 업무 환경이다",
            "실력 있는 사수를 만나지 못해 제대로 된 피드백을 받지 못하고 있다",
            "기술을 사용만 하고 왜 쓰는지에 대해서는 답을 못한다",
            "성장하기 위해서 무엇을, 어떻게 공부해야 하는지 모른다"
        ]
    },
    {
        title: '취업을 준비하는 대학생',
        list: [
            "기술을 사용만 하고 왜 쓰는지에 대해서는 답을 못한다",
            "학습한 이론을 실무에 어떻게 접목해야 하는지 모르고 있다",
            "실력 있는 개발자를 만나보기 힘들다",
            "학생끼리 교류하니 제대로 된 정보를 얻기 힘들다",
            "서비스 운영에서 정말 고민해야 하는 부분이 어떤 부분인지 모른다"
        ]
    }
]
