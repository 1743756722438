export default [
  {
    name: "Theo",
    info: [
      "- 안드로이드 유명 컨퍼런스 다수 발표 경험",
      "- 카카오 개발자 출신"
    ],
    tag: "안드로이드",
  },
  {
    name: "Pepe",
    info: [
      "- NC소프트 출신",
      "- 마이리얼트립 출신",
      "- 카카오 출신",
      "- 안드로이드 컨퍼런스 발표자 출신",
      "- 안드로이드 저서 집필",
    ],
    tag: "안드로이드",
  },
  {
    name: "Tucker",
    info: [
      "- 쿠팡 출신",
      "- 삼성 출신",
      "- 기술사 자격증 보유",
    ],
    tag: "안드로이드",
  },
  {
    name: "Lan",
    info: [
      "- SK 출신",
      "- 쿠팡 출신",
      "- 기술리더 경험",
    ],
    tag: "안드로이드",
  },
  {
    name: "Terry",
    info: [
      "- 하이퍼커넥트 출신",
      "- 삼성전자 출신",
    ],
    tag: "안드로이드",
  },
  {
    name: "Liam",
    info: [
      "- 영국에서 근무",
      "- 네이버 출신",
      "- 넥슨 출신",
    ],
    tag: "안드로이드",
  },
  {
    name: "Tony",
    info: [
      "- 네이버 출신",
      "- NHN 출신",
    ],
    tag: "안드로이드",
  },
  {
    name: "Jerry",
    info: [
      "- 라인 출신",
      "- LG전자 출신",
    ],
    tag: "안드로이드",
  },
  {
    name: "HWAN",
    info: [
      "- 스타트업에서 리드 개발자로 재직 중",
      "- 여러 회사에서 글로벌 대규모 서비스 처리 경험",
      "- 서버, 클라이언트, 인프라 등 폭 넓은 경험과 지식 보유",
      "- 하이퍼커넥트 HyperX 팀 개발 리더, 면접관 출신",
      "- 라인 개발자 출신",
    ],
    tag: "안드로이드",
  },
];
