export default [
  {
    name: "FITZ (대표 멘토)",
    info: [
      "- 네이버 개발자 출신 서버 개발자",
      "- 하이퍼커넥트 면접관, 테크 블로그 관리자 출신",
      "- Jackson, Redisson 오픈 소스 컨트리뷰터",
      "- AI 특허 보유",
      "- 기술 서적 단독 집필"
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Toby",
    info: [
      "- 토비의 스프링 저자",
      "- 다수의 IT기업 강연",
      "- 구독자 6천명 유튜브 채널 운영"
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Whiteship (백기선)",

    info: [
      "- 마이크로소프트 개발자",
      "- 네이버, 아마존 출신",
      "- 구독자 2만명 백기선 유튜브 채널 운영"
    ],
    tag: "자바 백엔드",
  },
  {
    name: "JASON",
    info: [
      "- 북미에서 시니어 개발자로 재직 중",
      "- 해외 여러 글로벌 기업에서 대규모 서비스 처리 경험",
      "- 해외 취업에 대한 인사이트 보유",
      "- 아마존 개발자 출신",
      "- 마이크로소프트 개발자 출신",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "GYUWON",
    info: [
      "- 서비스 기업 CTO로 재직 중",
      "- 다수의 기업 CTO 경력",
      "- 다수의 강연 경험",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "ERIC",
    info: [
      "- 북미에서 개발자로 재직 중",
      "- 해외 여러 글로벌 기업에서 대규모 서비스 처리 경험",
      "- 해외 취업에 대한 인사이트 보유",
      "- 서버, 클라이언트, 인프라 등 폭 넓은 경험과 지식 보유",
      "- 아마존 개발자 출신",
      "- 쿠팡 개발자 출신",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Karn",
    info: [
      "- 호주에서 개발자로 재직 중",
      "- 코틀린 인 액션 등 다수 유명서적 번역"
    ],
    tag: "자바 백엔드",
  },

  {
    name: "Hai",
    info: [
      "- Kafka 컨트리뷰터",
      "- 카카오 개발자 출신"
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Evan",
    info: [
      "- 카카오 개발팀 리더 출신",
      "- 삼성전자 출신"
    ],
    tag: "자바 백엔드",
  },
  {
    name: "HWAN",
    info: [
      "- 스타트업에서 리드 개발자로 재직 중",
      "- 여러 회사에서 글로벌 대규모 서비스 처리 경험",
      "- 서버, 클라이언트, 인프라 등 폭 넓은 경험과 지식 보유",
      "- 하이퍼커넥트 HyperX 팀 개발 리더, 면접관 출신",
      "- 라인 개발자 출신",
    ],
    tag: "자바 백엔드, 안드로이드",
  },
  {
    name: "Kei",
    info: [
      "- 카카오 개발팀 리더 출신",
      "- SK플래닛 출신",
      "- 여러 컨퍼런스 발표 경험",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Atin",
    info: [
      "- 쿠팡 출신 시니어 개발자",
      "- 쿠팡 면접관 출신",
      "- NHN 개발자 출신",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Masil",
    info: [
      "- 쿠팡 출신 시니어 개발자",
      "- 개발 경력 10년 이상",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Ryan.J",
    info: [
      "- 우아한형제들 개발팀장 출신",
      "- 개발 경력 10년 이상",
      "- 대형 프로젝트 다수 경험",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Tomo",
    info: [
      "- 이베이 출신",
      "- 삼성 출신",
      "- 개발 경력 10년 이상",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Rey",
    info: [
      "- 엔씨소프트 출신",
      "- 리눅스코리아 출신"
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Dodger",
    info: [
      "- 글로벌 커머스 기업 리더 출신",
      "- 초당 100만개 이상의 트래픽 처리 경험",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "HUBERT",
    info: [
      "- Event Driven Architecture 개발 경험",
      "- 다양한 대용량 아키텍쳐 설계 경험",
      "- 네이버 파이낸셜 출신 개발자",
      "- 카카오 페이 출신 개발자",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "MICHAEL",
    info: [
      "- AI 기업 CTO 재직 중",
      "- 라인 개발자, 면접관 출신",
      "- 삼성 개발자 출신",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "BRIGHT",
    info: [
      "- 스타트업 CTO",
      "- 스타트업 co-founder 출신",
      "- 토스 개발자 출신",
      "- 하이퍼커넥트 개발 팀 리더, 면접관 출신",
      "- 서버, 인프라 등 넓은 경험 보유 (MSA, K8S Migration 경험)",
      "- 네이버 파이낸셜, 우아한형제들 등 여러 IT 회사 최종 합격",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "JD",
    info: [
      "- 카카오 뱅크 개발자 출신",
      "- 네이버 개발자 출신",
      "- NHN 개발자 출신",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Joseph",
    info: [
      "- 라인 개발자 출신",
      "- 배민, 엔씨 등 여러 대기업 합격 최종 경험"
    ],
    tag: "자바 백엔드",
  },
  {
    name: "ROBERT",
    info: [
      "- 마켓컬리 개발자 출신",
      "- 카카오 개발자 출신",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "K",
    info: [
      "- 무신사 개발자 출신",
      "- 개발 경력 10년",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Lyan",
    info: [
      "- 라인, NC 개발자 출신",
      "- 유명 오픈소스 관련 활동",
    ],
    tag: "자바 백엔드",
  },
  {
    name: "Joy",
    info: [
      "- 스타트업 CTO 출신",
      "- 쿠팡, 토스 개발자 출신",
    ],
    tag: "자바 백엔드",
  },
];
