export default [
  {
    que: "정말 멘토링을 받고 네이버, 카카오 입사한 사람이 있나요? 거짓말 같아요.",
    answer: `지금도 계속 합격하시는 분들이 생기고 있으며, 멘토링을 시작하시면 바로 수료생과 교류가 가능하기 때문에 직접 눈으로 확인하실 수 있습니다.`,
  },
  {
    que: "멘토링 시간대는 어떻게 정하나요?",
    answer: `멘토링은 오전 부터 늦은 밤 까지 편하신 시간대에 모두 가능합니다. 신청서에 가능한 시간대를 모두 체크해 주시면, F-Lab에서 조율하여 일정을 잡아드립니다.`,
  },
  {
    que: "수료생 연봉 같은 수치가 너무 높은데 통계의 장난이 아닌가요?",
    answer: `저희는 수료하신 분들의 정보를 지속적으로 정리해나가고 있습니다. 특별히 높은 구간만 잡아 평균을 낸 것이 아닌, 전체 수료생들의 통계가 맞습니다. 즉 통계의 장난은 없습니다. 당연한 이야기지만 멘토링 중도에 이탈하신 분들은 통계에 들어가지 않습니다.`,
  },
  {
    que: "직장을 다니면서도 병행이 가능한가요?",
    answer: `네 가능합니다. 실제로 현업에 계시는 많은 분들께서 멘토링을 받고 있습니다. 멘토링은 밤에도 진행이 가능하여, 현재 하고 계시는 일과 병행이 가능한 구조 입니다. 다만 공부량이 많기 때문에 여유 시간은 최대한 공부에 쏟아 주시는 것을 권장드립니다.`,
  },
  {
    que: "대상은 어떻게 되나요?",
    answer: `대학생, 취준생부터 현직자까지 모두 대상으로 하고 있습니다. 현직 개발자보다 더 개발을 잘하고 싶은 대학생분들도 멘토링을 받을 수 있고, 현직에서 일하시면서 더 좋은 회사로 이직하고 싶은 많은 분들도 멘토링을 받고 있습니다.`,
  },
  {
    que: "개발을 완전 처음 하는데 할 수 있나요?",
    answer: `완전 처음 하시는 분들도 가능은 하지만, 기초부터 쌓아가야 하기에 진도가 더딜 수 있습니다.`,
  },
  {
    que: "환불 정책은 어떻게 되나요?",
    answer: `만약 중간에 그만두게 되실 경우, 남은 기간을 하루 단위로 계산하여 남은 날짜 만큼의 금액을 깔끔하게 환불해 드립니다.`,
  },
  {
    que: "멘토링 비용 외에 추가적으로 들어가는 비용이 있나요?",
    answer: `추가 비용은 받고 있지 않습니다. 다만 추천 해드리는 도서와 인터넷 강의는 직접 구매하셔야 하기 때문에, 해당하는 부수적인 비용이 생길 수 있습니다.`,
  },
  {
    que: "기간별 과정 차이가 궁금합니다.",
    answer: `F-Lab은 멘토링 방식이기 때문에 멘토님의 지식과 경험을 끝없이 전달받을 수 있어 멘토링 기간이 딱 정해져있지 않습니다. 즉 '결제 단위' 의 차이일 뿐이며, 멘토링 내용은 동일하며 더 배우고 싶으시다면 계속 연장이 가능합니다.`,
  },
  {
    que: "저는 소심해서 적극적으로 못하기 때문에 더 적극적인 멘토를 만나고 싶어요.",
    answer: `추후 개발자가 되면 업무에 대한 적극성도 매우 중요합니다. 비효율적인 부분이 있다면 적극적으로 개선 제의를 해야하며, 좋은 업무를 선점하기 위해 자기 어필도 적극적으로 해야합니다. "나는 소심하니까" 라는 자신에 대한 고정관념을 두지 않으셨으면 좋겠습니다. F-Lab에서는 이런 적극성을 키워드리기 위한 여러 장치들도 마련해두었으니 이런 소심함을 극복하기 위한 마음가짐을 준비해보시면 좋습니다.`,
  },
  {
    que: "멘토를 제가 직접 선택할 수 있나요?",
    answer: `멘토와 멘티의 가능한 스케줄을 종합하여 매칭을 해드리고 있기 때문에 멘토를 직접 선택하시는 것은 불가능합니다. 유명하지 않더라도 업계에는 엄청난 실력을 가진 사람들이 많이 숨어있습니다. F-Lab의 멘토님들은 어떤 분이든지 업계 최고의 스펙과 엄격한 검증을 거친 실력을 갖고 계시기 때문에 어떤 분께 받아도 고퀄리티의 멘토링을 받으실 수 있습니다.`,
  },
  {
    que: "멘토마다 차이가 있지 않나요? 이상한 멘토님을 만나면 어떡하죠?",
    answer: `F-Lab에서는 멘토를 선발할 때 유명하다고 무조건 선발하는 것이 아닌 아주 엄격한 검증을 거쳐 선발합니다. 지금도 네카라쿠배 등 유명 IT기업 출신 개발자들이 멘토로 지원하고 있고, 타이틀에 비해 꽤 낮은 합격률로 멘토를 선발하고 있습니다. 실제로 아마존, 마이크로소프트 등 해외 유명기업 개발자도 탈락한 사례가 있습니다. 그렇기에 어떤 멘토님을 만나더라도 깊은 지식에 접근하실 수 있으실겁니다.`,
  },

  {
    que: "이 멘토링 받으면 네카라쿠배 갈 수 있나요?",
    answer: `답부터 드리자면 아닙니다. 상위 10% 개발자가 되려면 그에 맞는 노력을 쏟아야합니다. 멘토님이 그러한 실력을 가질 수 있도록 한계 없이 성장할 수 있는 길을 제시해주시겠지만, 결국 본인이 열심히 하지 않으면 좋은 결과를 얻을 수 없습니다.`,
  },

  {
    que: "평균 연봉이 4500만원이라해서 들어왔는데, 저도 연봉 4500만원 받게 만들어주세요.",
    answer: `저희의 멘토링 성과가 매우 뛰어난 것은 사실이나, 이는 멘티분들이 스스로 열심히 해주셔서입니다. F-Lab에서는 길을 제시해주어 성장의 한계를 높혀줬을 뿐입니다. 멘토링을 받았다는 사실만으로 본인의 실력이 늘어나거나 연봉이 높아지지 않습니다.`,
  },
  {
    que: "멘토링 시작일은 정확히 언제인가요?",
    answer: `매 달 첫째 주에 시작합니다. 팀마다 멘토링을 진행하는 요일이 다르기 때문에 요일이 확정되어야 정확한 날짜를 알 수 있습니다.`,
  },

  {
    que: "몇 명이나 모집하나요?",
    answer: `멘토분들의 스케줄에 따라 매 달 유동적으로 모집하고 있습니다. 즉 TO가 가득 차면 조기 마감이 될 수 있습니다.`,
  },
  {
    que: "추후 멘토링 금액이 오르면 어떻게 되나요?",
    answer: `멘토링 금액이 오르더라도 기존에 결제하신 분들은 인상 없이 기존 결제금액 그대로 연장이 가능하십니다.`,
  },
  {
    que: "얼리버드로 구매하면 연장할때도 할인이 되나요?",
    answer: `첫 결제 금액 기준으로 연장 금액이 책정되기에 연장하실 때에도 할인 혜택을 받으실 수 있습니다.`,
  },
  {
    que: "멘토님의 신원은 어떻게 보장되나요?",
    answer: `멘토님은 F-Lab에 합류할 때마다 경력증명서를 받고있습니다. 즉 멘토님의 신원은 보장된 상태이며, 안전한 멘토링을 위해 멘토가 배정되면 F-Lab에서 멘토님의 신원보증서를 발급해드립니다.`,
  },
];
