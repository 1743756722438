export default [
  {
    name: "Kenneth",
    info: ["- 네이버 출신", "- 뤼이드 출신", "- 유명 컨퍼런스 발표자 출신"],
    tag: "파이썬백엔드",
  },
  {
    name: "Stephen",
    info: ["- 오늘의집 출신", "- AWS 출신"],
    tag: "파이썬백엔드",
  },
  {
    name: "Jorge",
    info: ["- 네이버 출신", "- 카카오브레인 출신"],
    tag: "파이썬백엔드",
  },
  {
    name: "Zion",
    info: ["- SKT 출신", "- 서울대 출신"],
    tag: "파이썬백엔드",
  },
];
