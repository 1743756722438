





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import backendAbouts from "@/data/abouts";
import backendMentors from "@/data/backend-mentors";
import backendProducts from "@/data/backend-products";
import backendSteps from "@/data/backend-steps";
import androidAbouts from "@/data/abouts-android";
import androidMentors from "@/data/android-mentors";
import androidProducts from "@/data/android-products";
import androidSteps from "@/data/android-stpes";
import frontendAbouts from "@/data/abouts-frontend";
import frontendMentors from "@/data/frontend-mentors";
import frontendProducts from "@/data/frontend-products";
import frontendSteps from "@/data/frontend-stpes";
import pythonAbouts from "@/data/abouts-python";
import pythonMentors from "@/data/python-mentors";
import pythonProducts from "@/data/python-products";
import pythonSteps from "@/data/python-stpes";
import reviews from "@/data/reviews";
import questions from "@/data/questions";
import { backEndGrowth } from "@/data/growth";
import { androidGrowth } from "@/data/growth";
import { frontendGrowth } from "@/data/growth";
import environment from "@/data/environment";

declare let kakaoPixel: Function;

dayjs.extend(calendar);

export default Vue.extend({
  components: {},
  data() {
    return {
      type: "backend",
      viewTag: "backend",
      typeName: "백엔드",

      viewSteps: backendSteps,
      selectedStep: 0,
      reviewModal: false,

      registerUrl: process.env.VUE_APP_REGISTER_URL,
      mentors: backendMentors,
      mentorImages: [
        require("@/assets/images/mentors/mentor01.png"),
        require("@/assets/images/mentors/mentor02.png"),
        require("@/assets/images/mentors/mentor03.png"),
        require("@/assets/images/mentors/mentor04.png"),
        require("@/assets/images/mentors/mentor05.png"),
        require("@/assets/images/mentors/mentor06.png"),
      ],
      reviews,
      questions,
      selectedReview: reviews[0],
      products: backendProducts,
      viewAbouts: backendAbouts,
      eventShow: "none",
      logoUrl: require("@/assets/images/design/logo_java.png"),
      growth: backEndGrowth,
      environment,
      stickyContainer: false,
      lastdayInMonth: dayjs().endOf("month")["$D"],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.loadData();
      }
    },
  },
  methods: {
    loadData() {
      if ("/android" === this.$route.path) {
        this.type = "android";
        this.viewTag = "android";
        this.typeName = "안드로이드";
        this.mentors = androidMentors;
        this.products = androidProducts;
        this.viewSteps = androidSteps;
        this.viewAbouts = androidAbouts;
        this.logoUrl = require("@/assets/images/design/logo_android.png");
        this.growth = androidGrowth;
      } else if ("/frontend" === this.$route.path) {
        this.type = "frontend";
        this.viewTag = "frontend";
        this.typeName = "프론트엔드";
        this.mentors = frontendMentors;
        this.products = frontendProducts;
        this.viewSteps = frontendSteps;
        this.viewAbouts = frontendAbouts;
        this.logoUrl = require("@/assets/images/design/logo_frontend.png");
        this.growth = frontendGrowth;
      } else if ("/python-backend" === this.$route.path) {
        this.type = "python-backend";
        this.viewTag = "python";
        this.typeName = "파이썬 백엔드";
        this.mentors = pythonMentors;
        this.products = pythonProducts;
        this.viewSteps = pythonSteps;
        this.viewAbouts = pythonAbouts;
        this.logoUrl = require("@/assets/images/design/logo_python.png");
        this.growth = backEndGrowth;
      } else {
        this.type = "backend";
        this.viewTag = "backend";
        this.mentors = backendMentors;
        this.products = backendProducts;
        this.viewSteps = backendSteps;
        this.viewAbouts = backendAbouts;
        this.logoUrl = require("@/assets/images/design/logo_java.png");
        this.growth = backEndGrowth;
      }
    },
    onClickReview(idx) {
      this.selectedReview = reviews[idx];
      this.reviewModal = true;
      document.body.classList.add("modal-open");
      this.$analytics.logEvent("click_review_modal_open", {
        index: idx,
      });
    },
    closeModal() {
      this.reviewModal = false;
      document.body.classList.remove("modal-open");
    },
    moveTab(idx) {
      this.selectedStep = idx;
    },
    getMonth(timestamp?: string): number {
      return dayjs(timestamp).month() + 1;
    },
    getMonthDayFormat(timestamp: string): string {
      return dayjs(timestamp).format("M월 D일");
    },
    goToApply(position: string) {
      this.$analytics.logEvent("click_apply", {
        type: this.type,
        position,
      });
      kakaoPixel("2082182413653656203").viewCart(this.viewTag);

      switch (this.type) {
        case "backend":
          window.open(
            `${
              this.registerUrl
            }/application?related_device_id=${this.$analytics.getAmplitudeDeviceId()}`
          );
          break;
        case "frontend":
          window.open(
            `${
              this.registerUrl
            }/application-frontend?related_device_id=${this.$analytics.getAmplitudeDeviceId()}`
          );
          break;
        case "android":
          window.open(
            `${
              this.registerUrl
            }/application-android?related_device_id=${this.$analytics.getAmplitudeDeviceId()}`
          );
          break;
        case "python-backend":
          window.open(
            `${
              this.registerUrl
            }/application-python-backend?related_device_id=${this.$analytics.getAmplitudeDeviceId()}`
          );
          break;
        default:
          alert("카카오톡 채널로 문의 주세요 :)");
      }
    },
    clickWithFriendApply() {
      this.$analytics.logEvent("click_with_friend_apply");
      window.open("https://board.f-lab.kr/with-friend");
    },
    clickDeferredPaymentApply() {
      this.$analytics.logEvent("click_deferred_payment_apply", {
        type: this.type,
      });

      switch (this.type) {
        case "android":
          window.open(
            "https://wewin.im/courses/flab-android-mentoring?utm_source=flab&utm_medium=affiliate&utm_campaign=flab_wewin&utm_content=flab_android_mentoring&utm_term=flab_wewin_android_mentoring"
          );
          break;
        case "python-backend":
          window.open(
            "https://wewin.im/courses/flab-mentoring?utm_source=F-Lab&utm_medium=affiliate&utm_campaign=flab_wewin&utm_content=flab_wewin_mentoring&utm_term=flab_wewin_mentoring"
          );
          break;
      }
    },
    clickContactUs() {
      this.$analytics.logEvent("click_contact_us", {
        type: "floating",
      });
      kakaoPixel("2082182413653656203").participation("Consulting");
    },
    expEventClick() {
      this.$analytics.logEvent("click_exp_event");
      this.goToApply("exp_event_btn");
    },
    clickReviewSlideButton(direction) {
      this.$analytics.logEvent("click_review_slide_btn", {
        direction,
      });
    },
    clickMentorSlideButton(direction) {
      this.$analytics.logEvent("click_mentor_slide_btn", {
        direction,
      });
    },
  },
  mounted() {
    this.$analytics.logEvent("view_intro", {
      type: this.type,
    });
    kakaoPixel("2082182413653656203").pageView(`intro_${this.viewTag}`);
  },
  created() {
    this.loadData();
  },
});
