
































































import { Component, Vue } from 'vue-property-decorator';
import resume from '../data/resumes'

@Component({
  name: 'Resume'
})

export default class Resume extends Vue {
  data() {
    return {
      resumes: resume
    };
  }

  mounted(): void {
    this.$analytics.logEvent('view_resume');
  }
}
