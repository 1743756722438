import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueCarousel from 'vue-carousel';
import VueLazy from 'vue-lazy';
import 'vue-lazy/dist/vue-lazy.css';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import '@/plugins/analytics.js';

import '@/assets/css/App.scss';
import '@/assets/css/layout.scss';
import '@/assets/css/component.scss';
import '@/assets/css/ui.scss';
import '@/assets/css/sub.scss';

Vue.config.productionTip = false;
Vue.use(VueLazy);
Vue.use(VueCarousel);
Vue.use(vueAwesomeCountdown, 'vac');

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
