













































































































































































import { Component, Vue } from 'vue-property-decorator'; 

@Component({
  name: 'Brand'
})

export default class Brand extends Vue { 
  mounted(): void {
    this.$analytics.logEvent('view_brand');
  }
}
