export default [
  {
    tab: "STEP 00-1",
    title: "F-Lab이 생각하는 이상적인 주니어 자바 백엔드 개발자",
    list: [
      "- 대용량 트래픽 처리를 위해 자신이 사용하는 기술의 원리와 사용 이유를 정확히 알고 사용합니다.",
      "- 빠르게 변화하는 비즈니스에 빠르게 대처하기 위해 객체지향의 원리를 이해하고 아키텍처를 설계합니다.",
      "- 서비스 운영을 위한 모니터링 및 지표를 분석할 수 있어야합니다.",
      "- 팀원간에 적극적이고 솔직한 커뮤니케이션을 통해 올바른 방향으로 협업할 수 있어야합니다.",
      "- 평생 학습해야하는 개발자의 특성상, 학습의 효율화를 통해 스스로 깊이있게 성장할 수 있는 노하우를 갖춰야합니다.",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 00-2",
    title: "이 멘토링의 목적",
    list: [
      "- 주요 IT 업계에서 요구하는 “제대로 된” 수준의 주니어 개발자가 되기 위한 학습.",
      "- 원리와 이유를 찾아갈 수 있는 깊이있는 학습 습관 들이기.",
      "- 프로젝트를 진행하며 팀원간의 원활한 협업을 위한 소프트스킬 학습 및 활용",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 00-3",
    title: "프로젝트 진행방식",
    list: [
      "- Github를 이용하여 협업합니다.",
      "- 프로젝트 주제는 자유, 멘토는 이 프로젝트를 “잘” 만들 수 있는 방법에 대해 가이드합니다.",
      "- 멘토는 멘티가 올린 코드에 대해 코드리뷰를 제공합니다.",
      "- Github를 이용해 이슈 및 진행 상황을 관리하며 협업을 위한 도구 사용법에 익숙해집니다.",
      "- 함께 성장할 수 있는 문화에 익숙해지기 위해 멘티들끼리도 멘토의 가이드 하에 서로에게 피드백과 코드리뷰를 진행합니다.",
      "- 프로젝트에 대한 것들은 최대한 문서화하고, 피드백을 받으며 읽는 사람을 배려하는 글 쓰기를 연습합니다.",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 01",
    title: "멘토링 신청 후",
    list: [
      "- 결제 완료시 멘토링 시작일까지 사전 준비 사항들에 대해 안내.",
      "- 매 월 22일, 팀 빌딩을 진행하며 카톡 연락을 통해 멘토링 시간 조율",
      "- 조율 완료시 멘토링 시간에 맞는 멘토 배정 후 팀 빌딩 완료",
      "- 매 월 마지막 주 토요일 오후 3시에 오프라인 OT 및 네트워킹 진행 (참석여부 자유)",
      "- 팀 빌딩 1주 후 배정받은 요일과 시간에 첫 멘토링 시작 (매 주 동일한 요일과 시간에 진행)",
    ],
    paragraph: "",
    images: [],
  },
  {
    tab: "STEP 02",
    title: "이론 공부 (멘토링 종료 시까지 상시 진행)",
    list: [
      "- 첫 6주 동안 기본 지식 습득을 위해 이론 공부 진행. (학습 속도에 따라 유동적으로 기간 조절.)",
      "- 이론 공부에 필요한 도서와 자료 등은 순서에 맞춰 멘토가 추천",
      "- 화상 멘토링시 멘토가 기술면접 방식으로 꼬리 질문을 통한 피드백과 해설 제공",
      "- 궁금한 점은 메신저를 통해 멘토에게 상시로 질문 가능",
    ],
    paragraph: `많은 개발자들이 자바를 사용하고 있음에도 자바에 대해 모르고 사용하고 있고, 마찬가지로 스프링을 사용하고 있음에도 스프링에 대해 잘 모르고 사용하는 경우가 많습니다. 개발자에게 이론은 매우 중요합니다. 단순한 기능 구현은 누구나 할 수 있지만 이론에 기반한 깊은 수준의 설계가 들어간 구현은 아무나 할 수 없습니다. 또한 이론 지식이 없다면 멘토님이 제공하는 코드리뷰 내용을 알아듣기 힘듭니다. 그래서 수준높은 프로젝트를 위한 사전 이론공부를 진행합니다.`,
    images: [require("@/assets/images/steps/backend/guide1.png")],
  },
  {
    tab: "STEP 03",
    title: "프로젝트 진행 (8주 ~ 멘토링 종료시까지)",
    list: [
      `- 프로젝트를 시작하더라도 이론 공부는 지속적으로 진행`,
      `- "프로젝트 완성"이 목표가 아닌 "기능 하나만 만들더라도 제대로 만드는 것"이 목표`,
      "- 고수준의 프로젝트 진행을 위해 멘토가 방향성 제시 및 코드리뷰 제공",
      "- 대규모 트래픽이 발생하는 환경을 가정하고 개발",
      "- 여러 기술들의 트레이드 오프를 고려해보고 사용",
      "- 어플리케이션의 동작을 보장하기 위해 테스트 작성",
    ],
    paragraph: `이론을 아무리 많이 공부하더라도 결국 본인의 코드에 설계와 코드에 녹이지 못하면 의미가 없습니다. 여태까지 지루한 과정을 거쳐 학습한 이론들을 코드에 잘 녹일 수 있도록 멘토가 방향성 제시와 코드 리뷰를 해드립니다.`,
    images: [
      require("@/assets/images/steps/backend/review1.jpg"),
      require("@/assets/images/steps/backend/review2.jpg"),
    ],
  },
  {
    tab: "STEP 04",
    title: "서버 배포 & 프로세스 자동화",
    list: [
      "- 개발한 어플리케이션을 클라우드 서버에 배포해보면서 실제 서비스처럼 서버 구성",
      "- CI/CD 등을 적용해보며 여러 단순 반복 작업들을 자동화하여 생산성과 업무 효율을 높히는 것 경험",
      "- 실제로 서비스를 운영하는 것과 동일하게 서버 아키텍처 구성",
    ],
    paragraph: "",
    images: [require("@/assets/images/steps/backend/project1.jpg")],
  },
  {
    tab: "STEP 05",
    title: "성능 테스트 & 성능 튜닝",
    list: [
      "- 많은 사용자가 접속하는 것처럼 대용량 트래픽을 발생시켜 서버에 부하를 주며 본인이 작성한 코드에 대한 성능을 확인",
      "- 서버의 지표들을 수집하여 병목지점을 찾아내고, 성능 튜닝을 진행",
      "- 취업/이직 과정에서 본인의 성과에 대한 구체적인 지표를 제시할 수 있는 기준 마련",
    ],
    paragraph: "",
    images: [require("@/assets/images/steps/backend/project2.jpg")],
  },
  {
    tab: "STEP 06",
    title: "모니터링 & 트러블 슈팅",
    list: [
      "- 서버에 문제가 발생하면 원인을 빠르게 찾아낼 수 있는 환경을 만들 수 있도록 가이드",
      "- 메모리, 스레드 덤프를 생성해 분석하는 등 여러 고급 트러블 슈팅 방식 가이드",
    ],
    paragraph: `서비스는 개발도 중요하지만 안정적으로 운영하는 것도 중요합니다.`,
    images: [require("@/assets/images/steps/backend/elastic-apm-tracing.png")],
  },
  {
    tab: "STEP 07",
    title: "가상화 등 기술 적용 & 새 프로젝트 진행",
    list: [
      "- 마이크로서비스 아키텍쳐 적용 등 서버 아키텍쳐를 더 깊은 곳까지 고려하며 구성",
      "- 새로운 기술들을 도입하여 새 프로젝트를 진행",
    ],
    paragraph: `모든 과정을 아주 깊게 공부해야하기 때문에 몇 개월 정도의 비교적 짧은 기간으로는 4~5번까지도 진행하기 어려울 수 있습니다. 하지만 빠르게 달리셔서 여기까지 오신다면 위와 같은 진행이 가능합니다.`,
    images: [require("@/assets/images/steps/backend/next-tech.jpg")],
  },
  {
    tab: "STEP 08",
    title: "취업 지원",
    list: [
      "- 회사 지원시 지원 전략 조언",
      "- 회사 지원시 기술면접, 인성면접에 대한 모의 면접 진행 & 피드백",
    ],
    paragraph: "",
    images: [],
  },
];
