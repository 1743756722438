export default [
  {
    name: "1. 카카오 합격자 이력서",
    filePath: "/pdf/1_kakao.pdf",
  },
  {
    name: "2. 무신사 합격자 이력서",
    filePath: "/pdf/2_musinsa.pdf",
  },
  {
    name: "3. 식스샵 합격자 이력서",
    filePath: "/pdf/3_sixshop.pdf",
  },
  {
    name: "4. 네이버 합격자 이력서",
    filePath: "/pdf/4_naver.pdf",
  },
  {
    name: "5. 카카오 스타일 합격자 이력서",
    filePath: "/pdf/5_kakaostyle.pdf",
  },
];
